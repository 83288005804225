import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { accountSlice } from './slices/accountSlice'
import { apiSlice } from './slices/apiSlice'
import { authSlice } from './slices/authSlice'

const reducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  // Persist only auth reducer,
  [authSlice.name]: persistReducer(
    {
      key: authSlice.name,
      version: 1,
      storage,
    },
    authSlice.reducer
  ),
  [accountSlice.name]: persistReducer(
    {
      key: accountSlice.name,
      version: 1,
      storage,
    },
    accountSlice.reducer
  ),
})

export const store = configureStore({
  reducer,
  middleware: (gDM) =>
    gDM({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(apiSlice.middleware),
})

setupListeners(store.dispatch)

export const persistor = persistStore(store)
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppStore = typeof store
