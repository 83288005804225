import { ElementStates, PortStates } from '../slices/types'

export const administrativeStateColor = (state: string) => {
  switch (state) {
    case PortStates.creation_pending:
    case PortStates.creation_proceed:
    case PortStates.delete_pending:
    case PortStates.delete_proceed:
    case ElementStates.creation_pending:
    case ElementStates.creation_proceed:
    case ElementStates.delete_pending:
    case ElementStates.delete_proceed:
      return 'var(--chips-deploying-full)'

    case PortStates.creation_error:
    case ElementStates.creation_error:
      return 'var(--chips-failed-full)'

    case PortStates.created:
    case ElementStates.deployed:
      return 'var(--chips-deployed-full)'

    case PortStates.delete_error:
    case ElementStates.delete_error:
      return 'var(--chips-failed-full)'

    case PortStates.deleted:
    case ElementStates.deleted:
      return 'var(--chips-draft-full)'

    default:
      break
  }
}
