// -- NPM IMPORTS
import { useCallback, type FC } from 'react'

// -- COMPONENT IMPORTS
import UsersList from './List'

// -- ASSETS IMPORTS
import { Route, Routes, useNavigate } from 'react-router-dom'
import { DrawerProvider } from '../../hooks/useDrawer'
import AddUser from './Add'
import DeleteUser from './Delete'
import EditUser from './Edit'
import RevokeTokens from './RevokeTokens'
import './style.scss'

type UsersProps = {}

const Users: FC<UsersProps> = () => {
  const navigate = useNavigate()
  const onClose = useCallback(() => {
    navigate('')
  }, [navigate])
  return (
    <DrawerProvider>
      <UsersList />
      <Routes>
        <Route path='create' element={<AddUser onClose={onClose} />} />
        <Route path='edit/:id' element={<EditUser onClose={onClose} />} />
        <Route
          path='revoke-tokens/:id'
          element={<RevokeTokens onClose={onClose} />}
        />
        <Route path='delete/:id' element={<DeleteUser onClose={onClose} />} />
      </Routes>
    </DrawerProvider>
  )
}

export default Users
