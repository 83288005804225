import type { XYPosition } from '@xyflow/react'
import { useEffect, useMemo, useState } from 'react'
import type {
  TransportElementV2,
  WorkspaceV2,
} from '../../../../../../../slices/types'
import { ElementStates, NodeType } from '../../../../../../../slices/types'
import type {
  LogicalAttachmentEdge,
  LogicalEdges,
  LogicalNodes,
  LogicalTransportNode,
} from '../../types'
import { LogicalEdgeType, LogicalNodeType } from '../../types'
import { physicalLinkId } from './utils'

interface FlowData {
  nodes: LogicalNodes[]
  edges: LogicalEdges[]
}

export const position: XYPosition = {
  x: 0,
  y: 0,
}

const nodeDefaults = {
  position,
  width: 350,
}

const useFlowData = (workspace: WorkspaceV2['data']): FlowData => {
  const [nodes, setNodes] = useState<LogicalNodes[]>([])
  const [edges, setEdges] = useState<LogicalEdges[]>([])

  const newAttachements: LogicalAttachmentEdge[] = useMemo(() => {
    if (!workspace) {
      return []
    }

    const { id: workspaceId } = workspace

    if (!workspace?.attachments?.length) {
      return []
    }

    return workspace.attachments
      .filter(({ nodeId, transportId }) => {
        return !!nodeId && !!transportId
      })
      .map((data) => {
        let { id, side, nodeId, transportId } = data

        let source = nodeId,
          target = transportId

        if (side === 'Z') {
          source = transportId
          target = nodeId
        }

        return {
          id,
          source,
          target,
          type: LogicalEdgeType.Attachment,
          data: {
            ...data,
            workspaceId,
          },
        }
      })
  }, [workspace])

  let {
    newNodes,
    newNodesLinks,
  }: {
    newNodes: LogicalNodes[]
    newNodesLinks: Exclude<LogicalEdges, LogicalAttachmentEdge>[]
  } = useMemo(() => {
    if (!workspace) {
      return { newNodes: [], newNodesLinks: [] }
    }

    const { id: workspaceId } = workspace

    let nodes: LogicalNodes[] = []
    let physicalLinks: Exclude<LogicalEdges, LogicalAttachmentEdge>[] = []

    ;(workspace?.nodes ?? []).forEach((element) => {
      const { id } = element

      switch (element.type) {
        case NodeType.Cloud:
          nodes = [
            ...nodes,
            {
              id,
              type: LogicalNodeType.Cloud,
              ...nodeDefaults,
              height: 300,
              className: `admin-state-${element.administrativeState}`,
              data: {
                ...element,
                workspaceId,
              },
            },
          ]
          const serviceProviderId = `${element.product.cspName}-${element.product.provider}`

          if (!nodes.some(({ id }) => serviceProviderId === id)) {
            nodes = [
              ...nodes,
              {
                id: serviceProviderId,
                type: LogicalNodeType.ServiceProvider,
                ...nodeDefaults,
                height: 100,
                className: `admin-state-${ElementStates.deployed}`,
                data: {
                  id: serviceProviderId,
                  administrativeState: ElementStates.deployed,
                  kind: 'service-provider',
                  name: `${element.product.provider} ${element.product.cspName}`,
                  provider: element.product.provider,
                  cspName: element.product.cspName,
                },
              },
            ]
          }
          physicalLinks = [
            ...physicalLinks,
            {
              id: physicalLinkId(id, serviceProviderId),
              type: LogicalEdgeType.ServiceProvider,
              source: id,
              target: serviceProviderId,
              data: {
                administrativeState: ElementStates[element.administrativeState],
              },
            },
          ]

          break
        case NodeType.Access:
          nodes = [
            ...nodes,
            {
              id,
              type: LogicalNodeType.Access,
              ...nodeDefaults,
              className: `admin-state-${element.administrativeState}`,
              height: 230,
              data: {
                ...element,
                workspaceId,
              },
            },
          ]

          const { physicalPort } = element

          if (
            physicalPort &&
            !nodes.some(({ id: idP }) => physicalPort.id === idP)
          ) {
            nodes = [
              ...nodes,
              {
                id: physicalPort.id,
                type: LogicalNodeType.PhysicalPort,
                ...nodeDefaults,
                className: `admin-state-${ElementStates.deployed}`,
                height: 200,
                data: {
                  ...physicalPort,
                  workspaceId,
                },
              },
            ]
          }

          if (physicalPort) {
            physicalLinks = [
              ...physicalLinks,
              {
                id: physicalLinkId(id, physicalPort.id),
                type: LogicalEdgeType.Physical,
                source: physicalPort.id,
                target: id,
              },
            ]
          }

          break
      }
    })

    const transports: LogicalTransportNode[] = (
      workspace?.transports ?? []
    ).map((element: TransportElementV2) => {
      const { id } = element

      return {
        id,
        type: LogicalNodeType.Transport,
        ...nodeDefaults,
        className: `admin-state-${element.administrativeState}`,
        height: 210,
        data: {
          ...element,
          workspaceId,
        },
      }
    })

    return {
      newNodes: [...nodes, ...transports],
      newNodesLinks: [...physicalLinks],
    }
  }, [workspace])

  useEffect(() => {
    setNodes([...newNodes])
  }, [newNodes])

  useEffect(() => {
    setEdges([...newNodesLinks, ...newAttachements])
  }, [newAttachements, newNodesLinks])

  return useMemo(
    () => ({
      nodes,
      edges,
    }),
    [edges, nodes]
  )
}

export default useFlowData
