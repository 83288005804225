import { API_PATH_ACCOUNTS } from '../environments/env'
import { apiSlice } from './apiSlice'
import type { User, UsersFilters } from './types'

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserById: builder.query<User, { accountId: string; id: string }>({
      query: ({ accountId, id }) =>
        `${API_PATH_ACCOUNTS}/${accountId}/users/${id}`,
      providesTags: ['UsersDetailed'],
      keepUnusedDataFor: 5,
    }),

    getUsers: builder.query<User[], string>({
      query: (accountId) => `${API_PATH_ACCOUNTS}/${accountId}/users`,
      providesTags: ['Users'],
      keepUnusedDataFor: 5,
    }),

    getFiltersUsers: builder.query<
      User[],
      { accountId: string; query: UsersFilters }
    >({
      query: ({ query, accountId }) => ({
        url: `${API_PATH_ACCOUNTS}/${accountId}/users`,
        params: query,
      }),
      providesTags: ['Users'],
    }),

    addUser: builder.mutation<
      User,
      { accountId: string; user: Pick<User, 'name' | 'profileId' | 'email'> }
    >({
      query: ({ accountId, user: body }) => ({
        url: `${API_PATH_ACCOUNTS}/${accountId}/users`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Users', 'UsersDetailed'],
    }),

    deleteUser: builder.mutation<User, { accountId: string; id: string }>({
      query: ({ accountId, id }) => ({
        url: `${API_PATH_ACCOUNTS}/${accountId}/users/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Users', 'UsersDetailed'],
    }),

    updateUser: builder.mutation<
      User,
      {
        accountId: string
        user: Pick<Partial<User>, 'profileId'> & Pick<User, 'id' | 'name'>
      }
    >({
      query({ accountId, user: { id, ...body } }) {
        return {
          url: `${API_PATH_ACCOUNTS}/${accountId}/users/${id}`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ['Users', 'UsersDetailed'],
    }),
  }),
})

export const {
  useGetUserByIdQuery,
  useGetUsersQuery,
  useAddUserMutation,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useGetFiltersUsersQuery,
} = usersApiSlice
