// -- NPM IMPORTS
import useMediaQuery from '@mui/material/useMediaQuery'
import { ThemeSwitcherProvider } from 'react-css-theme-switcher'
import { Route, Routes } from 'react-router-dom'

// -- COMPONENT IMPORTS
import Login from '../Login'
import SetPassword from '../Login/SetPassword'
import Sidebar from '../Sidebar'

// -- ASSETS IMPORTS
import { useMemo } from 'react'
import ForgotPassword from '../Login/ForgotPassword'
import LegalPage from '../Login/LegalPage'
import ResetPassword from '../Login/ResetPassword'
import Logout from '../Login/logout'
import RequireAuth from './RequireAuth'
import './style.scss'

const themes = {
  light: '/light.css',
  dark: '/dark.css',
} as const

export default function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const storedTheme = localStorage.getItem('selectedTheme') as 'dark' | 'light'

  const currentTheme = useMemo(() => {
    let theme: 'dark' | 'light' = storedTheme
    if (!theme) {
      theme = prefersDarkMode ? 'dark' : 'light'
    }
    return theme
  }, [prefersDarkMode, storedTheme])

  return (
    <ThemeSwitcherProvider defaultTheme={currentTheme} themeMap={themes}>
      <div className='app__wrapper'>
        <Routes>
          <Route path='/create/:token' element={<SetPassword />} />
          <Route path='/reset/:token' element={<ResetPassword />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/login' element={<Login />} />
          <Route path='/legal' element={<LegalPage />} />
          <Route path='/logout' element={<Logout />} />
          <Route element={<RequireAuth />}>
            <Route path='/*' element={<Sidebar />} />
          </Route>
        </Routes>
      </div>
    </ThemeSwitcherProvider>
  )
}
