import { ContentCopy, Key } from '@mui/icons-material'
import { Box, IconButton, Typography, styled } from '@mui/material'
import { format, isBefore, parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'
import TrashIcon from '../../../../assets/icons-24/trash.svg?react'
import { useSnackbar } from '../../../../hooks/useSnackbar'
import type { PersonalToken } from '../../../../slices/types'

const Container = styled(Box)`
  border: 1px solid var(--border-primary);
  border-radius: 4px;
`

const Row = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 24px;
`

const Name = styled(Box)`
  font-size: 14px;
  font-weight: 525;
  line-height: 20px;
  color: var(--content-primary);
`

const IconNameWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  min-width: 161px;
  gap: 12px;
`

const Description = styled(Name)`
  font-size: 14px;
  font-weight: 525;
  line-height: 20px;
  color: var(--content-secondary);
`

const NameDescriptionWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`

const NameDescriptionRowWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 376px;
  gap: 69px;
`

const TokenIconWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--border-primary);
  width: 40px;
  height: 40px;
  border-radius: 4px;
`

const ActionIconButton = styled(TokenIconWrapper)`
  border: 1px solid var(--border-primary);
  width: 40px;
  height: 32px;
  border-radius: 4px;
`
const TokenWrapper = styled(Box)`
  background: var(--background-secondary);
  min-height: 74px;
  width: 100%;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;
`

const ExpiresActionWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  min-width: 253px;
  justify-content: space-between;
`
const TokenTitle = styled(Box)`
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  color: var(--content-secondary);
`

const TokenTitleWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

type PersonalTokenCardProps = {
  token: PersonalToken
  onOpenRevoke: () => void
}

const PersonalTokenCard = ({ token, onOpenRevoke }: PersonalTokenCardProps) => {
  const { showSuccess } = useSnackbar()
  const { t } = useTranslation()

  const isExpired = (expiredAt: string) => {
    const parsedDate = parseISO(expiredAt)
    const today = new Date()
    return isBefore(parsedDate, today)
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(token.pat)
    showSuccess(t('settings.tokens.TOKEN_COPY'))
  }

  return (
    <Container>
      <Row>
        <NameDescriptionRowWrapper>
          <IconNameWrapper>
            <TokenIconWrapper>
              <Key fontSize='small' />
            </TokenIconWrapper>
            <NameDescriptionWrapper>
              <Name>{token.label}</Name>
              <Description>{t('settings.tokens.CARD_NAME')}</Description>
            </NameDescriptionWrapper>
          </IconNameWrapper>
          <NameDescriptionWrapper>
            <Name>{token.description}</Name>
            <Description>{t('settings.tokens.CARD_DESCRIPTION')}</Description>
          </NameDescriptionWrapper>
        </NameDescriptionRowWrapper>
        <ExpiresActionWrapper>
          <NameDescriptionWrapper>
            <Name
              sx={
                isExpired(token.expiredAt)
                  ? { color: 'var(--content-negative)' }
                  : {}
              }>
              {format(parseISO(token.expiredAt), 'dd/MM/yyyy')}
            </Name>
            <Description>
              {t(
                isExpired(token.expiredAt)
                  ? 'settings.tokens.CARD_EXPIRED'
                  : 'settings.tokens.CARD_EXPIRES'
              )}
            </Description>
          </NameDescriptionWrapper>
          <ActionIconButton>
            <IconButton onClick={onOpenRevoke}>
              <TrashIcon />
            </IconButton>
          </ActionIconButton>
        </ExpiresActionWrapper>
      </Row>
      {token.pat && (
        <Row>
          <TokenWrapper>
            <TokenTitleWrapper>
              <TokenTitle>{t('settings.tokens.CARD_TOKEN_TITLE')}</TokenTitle>
              <Typography
                sx={{
                  fontSize: 14,
                  fontStyle: 'italic',
                  color: 'var(--content-secondary)',
                }}>
                {t('settings.tokens.TOKEN_WARNING')}
              </Typography>
              <Name>{token.pat}</Name>
            </TokenTitleWrapper>
            <ActionIconButton>
              <IconButton onClick={handleCopy}>
                <ContentCopy
                  fontSize='small'
                  sx={{ color: 'var(--brand-alto, #499CE4)' }}
                />
              </IconButton>
            </ActionIconButton>
          </TokenWrapper>
        </Row>
      )}
    </Container>
  )
}

export default PersonalTokenCard
