// -- Import NPM
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

// --- Import Assets
import { Backdrop, CircularProgress } from '@mui/material'
import SnackbarAlert from '../../../components/modals/SnackbarAlert'
import useCurrentAccount from '../../../hooks/useCurrentAccount'
import { useSelfQuery } from '../../../slices/authApiSlice'
import { selectCurrentToken } from '../../../slices/authSlice'
import type { mfaPayload } from '../../../slices/types'
import MFAConfiguration from './mfaConfiguration'
import MFADeviceList from './mfaDeviceList'

export default function SecuritySettings() {
  const { currentAccount } = useCurrentAccount()
  const token = useSelector(selectCurrentToken)
  const { data: MeData } = useSelfQuery(null, {
    skip: !token,
  })

  // ---------------------------- //
  // ----- SNACKBAR CONTROL ----- //
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [severity, setSeverity] = useState('')
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [loading, setLoading] = useState(false)
  // ----- SNACKBAR CONTROL ----- //
  // ---------------------------- //

  const [showMfaState, setShowMfaState] = useState<boolean>(null)
  const [mfaGenerateData, setMfaGenerateData] = useState<mfaPayload>()

  useEffect(() => {
    if (MeData?.otpVerified) {
      setShowMfaState(true)
    }
  }, [MeData?.otpVerified, setShowMfaState])

  return (
    <>
      <section className='settings__security--container'>
        <Backdrop
          sx={{ color: 'var(--root-color-action)', zIndex: '9999' }}
          open={loading}>
          <CircularProgress color='inherit' />
        </Backdrop>
        {!showMfaState && (
          <MFAConfiguration
            currentAccount={currentAccount}
            MeData={MeData}
            mfaGenerateData={mfaGenerateData}
            setMfaGenerateData={setMfaGenerateData}
            setShowMfaState={setShowMfaState}
            setSnackbarMessage={setSnackbarMessage}
            setSeverity={setSeverity}
            setOpenSnackbar={setOpenSnackbar}
            setLoading={setLoading}
          />
        )}
        {showMfaState && (
          <MFADeviceList
            currentAccount={currentAccount}
            MeData={MeData}
            mfaGenerateData={mfaGenerateData}
            setShowMfaState={setShowMfaState}
            setSnackbarMessage={setSnackbarMessage}
            setSeverity={setSeverity}
            setOpenSnackbar={setOpenSnackbar}
            setLoading={setLoading}
          />
        )}
      </section>
      <SnackbarAlert
        anchors={{ vertical: 'bottom', horizontal: 'left' }}
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        severity={severity}
        snackbarMessage={snackbarMessage}
      />
    </>
  )
}
