import { Container } from '@mui/material'
import { ReactFlowProvider } from '@xyflow/react'
import type { FC } from 'react'
import { Outlet } from 'react-router-dom'
import Current from './Current'
import { FlowContextProvider } from './Flow/hooks/useFlowStore'
interface LogicalProps {}

const Logical: FC<LogicalProps> = () => {
  return (
    <Container
      style={{
        position: 'relative',
        height: '100%',
        width: '100%',
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        maxWidth: 'unset',
        padding: 'unset',
      }}>
      <Container style={{ flexGrow: 1, maxWidth: 'unset', padding: 'unset' }}>
        <FlowContextProvider>
          <ReactFlowProvider>
            <Current />
          </ReactFlowProvider>
        </FlowContextProvider>
      </Container>
      <Outlet />
    </Container>
  )
}

export default Logical
