import { Backdrop, Box, CircularProgress, Divider, styled } from '@mui/material'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { StyledActionButton } from '../../../../components/forms/StyledFormComponents'
import useCurrentAccount from '../../../../hooks/useCurrentAccount'
import { useGetPersonalTokensQuery } from '../../../../slices/accountApiSlice'
import { useSelfQuery } from '../../../../slices/authApiSlice'
import { selectCurrentToken } from '../../../../slices/authSlice'
import CreatePersonalToken from './CreatePersonalToken'
import PersonalTokenCard from './PersonalTokenCard'
import RevokeToken from './RevokeToken'

const Container = styled(Box)``

const Content = styled(Box)`
  padding: 0 40px;
`

const TitleActionWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 80px;
`

const Title = styled(Box)`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: var(--content-primary);
`

const TokensWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 58px;
  overflow: auto;
`

const TokenSettings = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const token = useSelector(selectCurrentToken)
  const { currentAccount } = useCurrentAccount()
  const { data: MeData } = useSelfQuery(null, {
    skip: !token,
  })

  const { data: tokens, isLoading } = useGetPersonalTokensQuery(
    {
      accountId: currentAccount?.id,
      userId: MeData?.id,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !(currentAccount?.id || MeData?.id),
    }
  )

  const onClose = useCallback(() => {
    navigate('')
  }, [navigate])

  const navigateCreateToken = useCallback(() => {
    navigate('create')
  }, [navigate])
  const navigateRevokeToken = useCallback(
    (tokenId: string) => () => {
      navigate(`revoke/${tokenId}`)
    },
    [navigate]
  )

  return (
    <Container>
      <Backdrop
        sx={{ color: 'var(--root-color-action)', zIndex: '9999' }}
        open={isLoading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <Divider />
      <Content>
        <TitleActionWrapper>
          <Title>{t('settings.tokens.TITLE')}</Title>
          <StyledActionButton
            style={{ width: 118, color: '#fff' }}
            type='submit'
            variant='contained'
            onClick={navigateCreateToken}>
            {t('settings.tokens.CREATE_TOKEN_BUTTON')}
          </StyledActionButton>
        </TitleActionWrapper>
        <TokensWrapper>
          {tokens?.map((token) => (
            <PersonalTokenCard
              key={token.id}
              token={token}
              onOpenRevoke={navigateRevokeToken(token.id)}
            />
          ))}
        </TokensWrapper>
      </Content>
      <Routes>
        <Route
          path='create'
          element={<CreatePersonalToken onClose={onClose} />}
        />
        <Route
          path='revoke/:tokenId'
          element={<RevokeToken onClose={onClose} />}
        />
      </Routes>
    </Container>
  )
}

export default TokenSettings
