import { Avatar, CardContent, CardHeader } from '@mui/material'
import type { NodeProps } from '@xyflow/react'
import { Handle, Position } from '@xyflow/react'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { StyledLVCard } from '../../../../../../components/cards/styledLogicalViewCard'
import OperationalStatus from '../../../../../../components/chips/operationalStatus'
import FormatMonthlyPrice from '../../../../../../components/format/monthlyPrice'
import { administrativeStateColor } from '../../../../../../hooks/administrativeStateColor'
import { displayElementType } from '../../../../../../hooks/displayElementType'
import { DisplayElementIcon } from '../../../../../../hooks/displayIcons'
import { humanizeBandwidth } from '../../../../../../hooks/humanizeBandwidth'
import useCurrentAccount from '../../../../../../hooks/useCurrentAccount'
import { useGetElementOperationalStatusMetricsQuery } from '../../../../../../slices/MetricsApiSlice'
import type { LogicalPhysicalPortNode } from '../types'
import LogicalCardRows from './CardRows'

const PhysicalPort: FC<NodeProps<LogicalPhysicalPortNode>> = ({
  id,
  width,
  height,
  sourcePosition = Position.Bottom,
  data,
  selected,
}) => {
  const { name, administrativeState, product } = data
  const { t } = useTranslation()
  const { currentAccount } = useCurrentAccount()
  const { workspaceId } = useParams()

  const { data: operationalStatusData } =
    useGetElementOperationalStatusMetricsQuery(
      {
        accountId: currentAccount?.id,
        elementType: 'port',
        id: data?.id,
        workspaceId: workspaceId,
      },
      {
        refetchOnMountOrArgChange: true,
        skip: !data || data.administrativeState !== 'deployed',
      }
    )

  return (
    <>
      <StyledLVCard
        variant='outlined'
        key={id}
        style={{
          width,
          height,
          borderColor: `${administrativeStateColor(administrativeState)}`,
        }}>
        <CardHeader
          titleTypographyProps={{
            noWrap: true,
            textOverflow: 'ellipsis',
          }}
          avatar={
            <Avatar variant='square'>
              <DisplayElementIcon kind='port' />
            </Avatar>
          }
          title={name}
          subheader={displayElementType('port', 'PHYSICAL', t)}
        />
        <CardContent>
          <LogicalCardRows
            title={t('element.CSP_LOCATION')}
            value={product.location}
          />
          <LogicalCardRows
            title={t('element.BANDWIDTH')}
            value={humanizeBandwidth(product.bandwidth)}
          />
          <LogicalCardRows
            title={t('element.MRC')}
            value={<FormatMonthlyPrice price={product.priceMrc} />}
          />
          <LogicalCardRows
            title={t('element.NODE_OP_STATUS')}
            value={
              <OperationalStatus
                status={operationalStatusData?.status}
                showStatusText={true}
              />
            }
          />
        </CardContent>
        <CardContent>
          <LogicalCardRows
            title={t('element.PROVIDER')}
            value={product.provider}
          />
        </CardContent>
        <Handle
          isConnectable={false}
          style={{
            pointerEvents: 'none',
          }}
          position={sourcePosition}
          type='source'
        />
      </StyledLVCard>
    </>
  )
}

export default PhysicalPort
