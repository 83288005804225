import type { FC } from 'react'
import { useParams } from 'react-router-dom'
import useCurrentAccount from '../../../../../../hooks/useCurrentAccount'
import Workspace from './Workspace'

interface CurrentProps {}
const Current: FC<CurrentProps> = () => {
  const { currentAccount } = useCurrentAccount()
  const { workspaceId } = useParams()

  return <Workspace accountId={currentAccount?.id} workspaceId={workspaceId} />
}

export default Current
