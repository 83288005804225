// Import NPM
import type { FC } from 'react'

// -- Import Assets
import PortsList from './List'

import './style.scss'

type PortsProps = {}

const Ports: FC<PortsProps> = () => {
  return <PortsList />
}

export default Ports
