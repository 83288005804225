import { Avatar, Card, CardContent, CardHeader, Toolbar } from '@mui/material'
import type { NodeProps } from '@xyflow/react'
import {
  Handle,
  NodeToolbar,
  Position,
  useHandleConnections,
  useReactFlow,
} from '@xyflow/react'
import type { FC } from 'react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import AttachmentIcon from '../../../../../../assets/icons-io-v1/attachment.svg?react'
import TrashIcon from '../../../../../../assets/icons-io-v1/delete.svg?react'
import DetachmentIcon from '../../../../../../assets/icons-io-v1/detachment.svg?react'
import EditIcon from '../../../../../../assets/icons-io-v1/edit.svg?react'
import ShowIcon from '../../../../../../assets/icons-io-v1/show.svg?react'
import { StyledLVCard } from '../../../../../../components/cards/styledLogicalViewCard'
import OperationalStatus from '../../../../../../components/chips/operationalStatus'
import FormatMonthlyPrice from '../../../../../../components/format/monthlyPrice'
import { StyledIdCardIconButton } from '../../../../../../components/forms/SyledIconButtons'
import Visible from '../../../../../../components/permissions/Visible'
import { administrativeStateColor } from '../../../../../../hooks/administrativeStateColor'
import { displayElementType } from '../../../../../../hooks/displayElementType'
import { DisplayElementIcon } from '../../../../../../hooks/displayIcons'
import { humanizeBandwidth } from '../../../../../../hooks/humanizeBandwidth'
import useCurrentAccount from '../../../../../../hooks/useCurrentAccount'
import { ResourceType } from '../../../../../../slices/authorizationsApiSlice'
import { useGetElementOperationalStatusMetricsQuery } from '../../../../../../slices/MetricsApiSlice'
import { ElementStates } from '../../../../../../slices/types'
import {
  disableActionsForErrorStates,
  disableAdministrativeState,
} from '../../../../../../utils/states'
import useLayoutForm from '../Flow/hooks/useLayoutForm'
import type { LogicalTransportNode } from '../types'
import LogicalCardRows from './CardRows'

const Transport: FC<NodeProps<LogicalTransportNode>> = ({
  id,
  width,
  height,
  targetPosition,
  sourcePosition,
  data,
  selected,
}) => {
  const { name, administrativeState, product } = data
  const { t } = useTranslation()
  const { currentAccount } = useCurrentAccount()
  const { workspaceId } = useParams()
  const navigate = useNavigate()

  const { data: operationalStatusData } =
    useGetElementOperationalStatusMetricsQuery(
      {
        accountId: currentAccount?.id,
        elementType: 'transport',
        id: data?.id,
        workspaceId: workspaceId,
      },
      {
        refetchOnMountOrArgChange: true,
        skip: !data || data.administrativeState !== 'deployed',
      }
    )

  const { selectedDirection } = useLayoutForm()

  if (!targetPosition || !sourcePosition) {
    switch (selectedDirection) {
      case 'DOWN':
        targetPosition = Position.Top
        sourcePosition = Position.Bottom
        break
      case 'RIGHT':
        targetPosition = Position.Left
        sourcePosition = Position.Right
    }
  }

  const { getEdge, getNode } = useReactFlow()

  const sourceConnections = useHandleConnections({ type: 'source' })
  const targetConnections = useHandleConnections({ type: 'target' })

  const isSourceAttached = sourceConnections.length > 0
  const isTargetAttached = targetConnections.length > 0

  const showDetails = useCallback(() => {
    navigate(`details/transport/${id}/properties`)
  }, [id, navigate])
  const showDelete = useCallback(() => {
    navigate(`delete/transport/${id}`)
  }, [id, navigate])
  const showEdit = useCallback(() => {
    navigate(`edit/transport/${id}`)
  }, [id, navigate])

  const detachSource = useCallback(() => {
    if (isSourceAttached) {
      const edge = getEdge(sourceConnections[0].edgeId)
      const node = getNode(sourceConnections[0].target)
      console.log({ node })
      navigate(
        `attachment/${node.data.type}/${node.data.id}/${edge.data['side']}`
      )
    }
  }, [getEdge, getNode, isSourceAttached, navigate, sourceConnections])

  const detachTarget = useCallback(() => {
    if (isSourceAttached) {
      const edge = getEdge(targetConnections[0].edgeId)
      const node = getNode(targetConnections[0].source)
      console.log({ node })
      navigate(
        `attachment/${node.data.type}/${node.data.id}/${edge.data['side']}`
      )
    }
  }, [getEdge, getNode, isSourceAttached, navigate, targetConnections])

  const attach = useCallback(() => {
    navigate(`attachment/transport/${data?.id}`)
  }, [data?.id, navigate])

  return (
    <>
      <NodeToolbar isVisible={selected}>
        <Card>
          <Toolbar>
            <Visible
              resource={ResourceType.Workspace}
              resourceId={workspaceId}
              action='get_transport'>
              <div className='accordion__container-trash-icon'>
                <StyledIdCardIconButton
                  disabled={disableAdministrativeState(administrativeState)}
                  onClick={showDetails}>
                  <ShowIcon />
                </StyledIdCardIconButton>
              </div>
            </Visible>
            <Visible
              resource={ResourceType.Workspace}
              resourceId={workspaceId}
              action='edit_transport'>
              <div className='accordion__container-trash-icon'>
                <StyledIdCardIconButton
                  disabled={
                    disableAdministrativeState(administrativeState) ||
                    disableActionsForErrorStates(administrativeState)
                  }
                  onClick={showEdit}>
                  <EditIcon />
                </StyledIdCardIconButton>
              </div>
            </Visible>
            <Visible
              resource={ResourceType.Workspace}
              resourceId={workspaceId}
              action='delete_transport'>
              <div className='accordion__container-trash-icon'>
                <StyledIdCardIconButton
                  disabled={
                    disableAdministrativeState(administrativeState) ||
                    isSourceAttached ||
                    isTargetAttached
                  }
                  onClick={showDelete}>
                  <TrashIcon />
                </StyledIdCardIconButton>
              </div>
            </Visible>

            {!isTargetAttached ? (
              <Visible
                resource={ResourceType.Workspace}
                resourceId={data?.id}
                action='get_attachment'>
                <div className='accordion__container-trash-icon'>
                  <StyledIdCardIconButton onClick={attach}>
                    {administrativeState === ElementStates.deployed && (
                      <AttachmentIcon />
                    )}
                  </StyledIdCardIconButton>
                </div>
              </Visible>
            ) : (
              <Visible
                resource={ResourceType.Workspace}
                resourceId={data?.id}
                action='delete_attachment'>
                <div className='accordion__container-trash-icon'>
                  <StyledIdCardIconButton onClick={detachTarget}>
                    {administrativeState === ElementStates.deployed && (
                      <DetachmentIcon />
                    )}
                  </StyledIdCardIconButton>
                </div>
              </Visible>
            )}

            {!isSourceAttached ? (
              <Visible
                resource={ResourceType.Workspace}
                resourceId={data?.id}
                action='get_attachment'>
                <div className='accordion__container-trash-icon'>
                  <StyledIdCardIconButton onClick={attach}>
                    {administrativeState === ElementStates.deployed && (
                      <AttachmentIcon />
                    )}
                  </StyledIdCardIconButton>
                </div>
              </Visible>
            ) : (
              <Visible
                resource={ResourceType.Workspace}
                resourceId={data?.id}
                action='delete_attachment'>
                <div className='accordion__container-trash-icon'>
                  <StyledIdCardIconButton onClick={detachSource}>
                    {administrativeState === ElementStates.deployed && (
                      <DetachmentIcon />
                    )}
                  </StyledIdCardIconButton>
                </div>
              </Visible>
            )}
          </Toolbar>
        </Card>
      </NodeToolbar>
      <StyledLVCard
        variant='outlined'
        key={id}
        style={{
          height,
          width,
          borderColor: `${administrativeStateColor(administrativeState)}`,
        }}>
        <Handle position={targetPosition} type='target' />
        <Handle position={sourcePosition} type='source' />

        <CardHeader
          titleTypographyProps={{
            noWrap: true,
            textOverflow: 'ellipsis',
          }}
          avatar={
            <Avatar variant='square'>
              <DisplayElementIcon kind='transport' />
            </Avatar>
          }
          title={name}
          subheader={displayElementType('transport', '', t)}
        />
        <CardContent>
          <LogicalCardRows
            title={t('element.CSP_LOCATION')}
            value={product.location}
          />
          <LogicalCardRows
            title={t('element.CSP_LOCATION')}
            value={product.locationTo}
          />
          <LogicalCardRows
            title={t('element.BANDWIDTH')}
            value={humanizeBandwidth(product.bandwidth)}
          />
          <LogicalCardRows
            title={t('element.MRC')}
            value={<FormatMonthlyPrice price={product.priceMrc} />}
          />
          <LogicalCardRows
            title={t('element.NODE_OP_STATUS')}
            value={
              <OperationalStatus
                status={operationalStatusData?.status}
                showStatusText={true}
              />
            }
          />
        </CardContent>
        <CardContent>
          <LogicalCardRows
            title={t('element.PROVIDER')}
            value={product.provider}
          />
        </CardContent>
      </StyledLVCard>
    </>
  )
}

export default Transport
