import type { EdgeChange, ReactFlowProps } from '@xyflow/react'
import { applyEdgeChanges, applyNodeChanges } from '@xyflow/react'
import type { Dispatch, FC, PropsWithChildren, SetStateAction } from 'react'
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import type { LogicalEdges, LogicalNodes } from '../../types'

type LogicalReactFlowProps = ReactFlowProps<LogicalNodes, LogicalEdges>

type FlowContextType = {
  nodes: LogicalNodes[]
  edges: LogicalEdges[]
  setNodes: Dispatch<SetStateAction<LogicalNodes[]>>
  setEdges: Dispatch<SetStateAction<LogicalEdges[]>>

  // onNodesChange: (changes: NodeChange<LogicalNodes>[]) => void
  onNodesChange: LogicalReactFlowProps['onNodesChange']
  onEdgesChange: LogicalReactFlowProps['onEdgesChange']
}

const FlowContext = createContext<FlowContextType>(null)

type FlowContextProviderProps = {}

export const FlowContextProvider: FC<
  PropsWithChildren<FlowContextProviderProps>
> = ({ children }) => {
  const [nodes, setNodes] = useState<LogicalNodes[]>([])
  const [edges, setEdges] = useState<LogicalEdges[]>([])

  const onNodesChange: FlowContextType['onNodesChange'] = useCallback(
    (changes) => {
      setNodes((previous) => {
        return applyNodeChanges(changes, previous)
      })
    },
    []
  )
  const onEdgesChange: FlowContextType['onEdgesChange'] = useCallback(
    (changes: EdgeChange<LogicalEdges>[]) => {
      setEdges((previous) => {
        return applyEdgeChanges(changes, previous)
      })
    },
    []
  )

  const value = useMemo(
    () => ({
      nodes,
      edges,
      onNodesChange,
      onEdgesChange,
      setEdges,
      setNodes,
    }),
    [edges, nodes, onEdgesChange, onNodesChange]
  )

  return <FlowContext.Provider value={value}>{children}</FlowContext.Provider>
}

const useFlowStore = () => useContext(FlowContext)

export default useFlowStore
