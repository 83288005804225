// -- NPM IMPORTS
import { Backdrop, CircularProgress, InputLabel } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useState } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

// -- COMPONENT IMPORTS
import {
  StyledActionButton,
  StyledTextField,
} from '../../components/forms/StyledFormComponents'
import { useSendResetPasswordMutation } from '../../slices/setPassWordAPISlice'

// -- ASSETS IMPORTS
import TopLogo from '../../assets/logos/horizontal-small.svg?react'
import { useSnackbar } from '../../hooks/useSnackbar'
import './style.scss'

interface formInput {
  email: string
}

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  color: 'var(--content-secondary)',
  fontSize: '15px',
  marginBottom: '0px',
}))

export default function ForgotPassword() {
  const { showError, showSuccess } = useSnackbar()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [sendResetPassword] = useSendResetPasswordMutation()

  const { register, handleSubmit } = useForm<formInput>({
    shouldUseNativeValidation: true,
  })

  const [loading, setLoading] = useState(false)

  const handleForm: SubmitHandler<formInput> = async (data) => {
    try {
      // TODO Plug API Route when available //
      await sendResetPassword({ ...data }).unwrap()
      navigate('/login')
      showSuccess(t('login.PASSWORD_RESET_EMAIL_SENT'))
    } catch (error) {
      setLoading(false)
      showError(error)
    }
  }

  return (
    <>
      <main className='login__main'>
        <Backdrop
          sx={{ color: 'var(--root-color-action)', zIndex: '9999' }}
          open={loading}>
          <CircularProgress color='inherit' />
        </Backdrop>
        <section className='login__main-container--center'>
          <div
            onClick={() => navigate('/login')}
            className='login__top-container--reset'>
            <TopLogo />
          </div>
          <div className='login__form-container--reset'>
            <h1>{t('login.HEADER_FORGOT_PASSWORD')}</h1>
            <form onSubmit={handleSubmit(handleForm)}>
              <div>
                <p className='login__first-connection'>
                  {t('login.MESSAGE_FORGOT_PASSWORD_DISCLAIMER')}
                </p>
                <div className='login__form-container--field'>
                  <StyledInputLabel shrink htmlFor='email'>
                    {t('login.EMAIL')}
                  </StyledInputLabel>
                  <StyledTextField
                    required
                    id='email'
                    variant='outlined'
                    type='email'
                    key='1'
                    autoFocus
                    slotProps={{
                      htmlInput: {
                        form: {
                          autocomplete: 'off',
                        },
                      },
                    }}
                    {...register('email', {
                      required: t('login.PASSWORD_REQUIRED'),
                    })}
                  />
                </div>
                <div className='login__form-container--field-button'>
                  <StyledActionButton
                    type='submit'
                    color='primary'
                    variant='contained'>
                    {t('login.SEND_RESET_EMAIL_BUTTON')}
                  </StyledActionButton>
                </div>
              </div>
            </form>
          </div>
        </section>
      </main>
    </>
  )
}
