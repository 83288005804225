// -- Import NPM
import Button from '@mui/material/Button'
import { QRCodeSVG } from 'qrcode.react'
import { useCallback, useEffect, useState } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyledTextField } from '../../../components/forms/StyledFormComponents'

// --- Import Assets
import { IconButton } from '@mui/material'
import Copy from '../../../assets/icons-24/copy.svg?react'
import { StyleButton } from '../../../components/buttons/StyledButton'
import { useSnackbar } from '../../../hooks/useSnackbar'
import {
  useMfaGenerateMutation,
  useMfaVerifyMutation,
} from '../../../slices/settingsApiSlice'
import type { User, mfaPayload } from '../../../slices/types'

type MfaConfigProps = {
  currentAccount: { id: string }
  MeData: User
  mfaGenerateData: mfaPayload
  setMfaGenerateData: React.Dispatch<React.SetStateAction<mfaPayload>>
  setShowMfaState: React.Dispatch<React.SetStateAction<boolean>>
  setOpenSnackbar?: React.Dispatch<React.SetStateAction<boolean>>
  setSeverity?: React.Dispatch<React.SetStateAction<string>>
  setSnackbarMessage?: React.Dispatch<React.SetStateAction<string>>
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

type formInput = {
  token: number
}

export default function MFAConfiguration({
  currentAccount,
  MeData,
  mfaGenerateData,
  setMfaGenerateData,
  setShowMfaState,
  setLoading,
}: MfaConfigProps) {
  const { showError, showSuccess } = useSnackbar()
  const [generateMFA] = useMfaGenerateMutation()
  const [verifyMFA] = useMfaVerifyMutation()
  const { t } = useTranslation()
  const [mfaState, setMfaState] = useState<boolean>(true)
  const [mfaDeviceName, setMfaDevicename] = useState<string>(null)
  const { register, handleSubmit } = useForm<formInput>({
    shouldUseNativeValidation: true,
  })

  useEffect(() => {
    if (MeData?.otpAuthURL) {
      setMfaGenerateData({
        device: MeData?.device,
        otpAuthUrl: MeData?.otpAuthURL,
        otpCreatedAt: MeData?.otpCreatedAt,
      })
      setMfaState(false)
    }
  }, [
    MeData?.device,
    MeData?.otpAuthURL,
    MeData?.otpCreatedAt,
    setMfaGenerateData,
  ])

  const onSubmit: SubmitHandler<formInput> = useCallback(
    async (data) => {
      setLoading(true)
      try {
        await verifyMFA({
          accountId: currentAccount?.id,
          userId: MeData?.id,
          body: {
            token: data?.token,
          },
        }).unwrap()
        setLoading(false)
        setShowMfaState(true)
        setMfaGenerateData(null)
        showSuccess(t('settings.mfa.ENABLE_SUCCESS'))
      } catch (e) {
        setLoading(false)
        showError(e)
      }
    },
    [
      verifyMFA,
      currentAccount?.id,
      MeData?.id,
      setShowMfaState,
      setMfaGenerateData,
      t,
      setLoading,
      showSuccess,
      showError,
    ]
  )

  const handleAddMfaDevice = useCallback(async () => {
    setLoading(true)
    try {
      const result = await generateMFA({
        accountId: currentAccount?.id,
        userId: MeData?.id,
        body: {
          device: mfaDeviceName,
        },
      }).unwrap()
      setLoading(false)
      setMfaGenerateData(result)
    } catch (e) {
      setLoading(false)
      showError(e)
    }
  }, [
    MeData?.id,
    currentAccount?.id,
    generateMFA,
    mfaDeviceName,
    setMfaGenerateData,
    setLoading,
    showError,
  ])

  return (
    <>
      <div className='settings__mfa--header'>{t('settings.mfa.TITLE')}</div>
      <div className='settings__mfa--phrase'>{t('settings.mfa.PHRASE')}</div>
      <div className='settings__mfa--add-button'>
        <StyleButton onClick={() => setMfaState(!mfaState)} variant='contained'>
          {t('settings.mfa.ENABLE')}
        </StyleButton>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={mfaState ? 'hide' : 'settings__mfa--config-container'}>
          <div className='settings__mfa--config-title'>
            {t('settings.mfa.CONFIGURATION')}
          </div>
          <div className='settings__mfa--config-steps'>
            {t('settings.mfa.STEP_1')}
            <span> ({t('settings.mfa.STEP_1_bis')})</span>
          </div>
          <div className='settings__mfa--config-steps'>
            {t('settings.mfa.STEP_2')}
          </div>
          <StyledTextField
            required
            onChange={(e) => setMfaDevicename(e.target.value)}
            defaultValue={
              MeData?.device ? MeData?.device : mfaGenerateData?.device
            }
            variant='outlined'
            sx={{
              marginLeft: '52px',
              maxWidth: '200px',
              paddingTop: '10px',
            }}
            slotProps={{
              htmlInput: {
                form: {
                  autocomplete: 'off',
                },
              },
            }}
          />
          <div className='settings__mfa--config-steps'>
            {t('settings.mfa.STEP_3')}
          </div>
          <div>
            <div className={mfaGenerateData?.device ? 'hide' : 'show'}>
              <Button
                sx={{
                  marginLeft: '52px',
                  marginTop: '10px',
                }}
                variant='contained'
                onClick={() => handleAddMfaDevice()}
                disabled={
                  mfaDeviceName === null || mfaDeviceName?.length <= 0
                    ? true
                    : false
                }>
                Display QR Code
              </Button>
            </div>
            <div className='settings__mfa--config-qr-code'>
              {mfaGenerateData?.otpAuthUrl && (
                <>
                  <div className='settings__mfa--config-qr-code-container'>
                    <QRCodeSVG
                      value={
                        mfaGenerateData?.otpAuthUrl
                          ? mfaGenerateData?.otpAuthUrl
                          : ''
                      }
                      bgColor='var(--background-secondary)'
                      fgColor='var(--content-primary)'
                      size={200}
                    />
                    <div>
                      <div>Secret Key </div>
                      <div>
                        {MeData?.otpSecret}
                        <IconButton
                          sx={{
                            marginLeft: '5px',
                            padding: '5px 10px',
                          }}
                          onClick={() => {
                            navigator.clipboard.writeText(MeData?.otpSecret)
                          }}>
                          <Copy />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                  <div className='settings__mfa--config-verif-code'>
                    {t('settings.mfa.VERIF_CODE')}
                  </div>
                  <StyledTextField
                    required
                    variant='outlined'
                    sx={{
                      maxWidth: '200px',
                      paddingTop: '10px',
                    }}
                    slotProps={{
                      htmlInput: {
                        form: {
                          autocomplete: 'off',
                        },
                      },
                    }}
                    {...register('token', {
                      required: t('user.NAME_REQUIRED'),
                    })}
                  />
                  <Button
                    type='submit'
                    variant='contained'
                    sx={{
                      maxWidth: '200px',
                      marginTop: '15px',
                    }}>
                    {t('settings.mfa.REGISTER')}
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  )
}
