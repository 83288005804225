import { Box, IconButton, styled, Typography } from '@mui/material'
import { useCallback, type FC } from 'react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { MainDrawer } from '../../components/drawer/MainDrawer'
import {
  StyledCheckBox,
  StyledFormControlLabel,
} from '../../components/fields/StyledCheckBox'
import {
  StyledActionButton,
  StyledInputLabel,
  StyledTextField,
} from '../../components/forms/StyledFormComponents'
import useCurrentAccount from '../../hooks/useCurrentAccount'
import { useSnackbar } from '../../hooks/useSnackbar'
import { UserProfileType } from '../../slices/types'
import { useAddUserMutation } from '../../slices/usersApiSlice'

export const StyleButtonClose = styled(IconButton)(() => ({
  width: '24px',
  height: '24px',
  padding: 0,
  '& svg': {
    width: '24px',
    height: '24px',
  },
}))

interface AddUserProps {
  onClose: () => void
}

interface FormInput {
  name: string
  email: string
  profileId: UserProfileType
}

const AddUser: FC<AddUserProps> = ({ onClose }) => {
  const { t } = useTranslation()

  const { register, handleSubmit, trigger } = useForm<FormInput>({
    shouldUseNativeValidation: true,
  })
  const { currentAccount } = useCurrentAccount()
  const [createUser, { isLoading }] = useAddUserMutation()
  const { showError, showSuccess } = useSnackbar()

  const onSubmit: SubmitHandler<FormInput> = useCallback(
    async (data) => {
      const { ...newData } = data
      if (newData.profileId !== UserProfileType.AccountAdmin)
        newData.profileId = UserProfileType.AccountView

      try {
        await createUser({
          accountId: currentAccount?.id,
          user: newData,
        }).unwrap()

        showSuccess(t('user.ADDED_USER'))
        onClose()
      } catch (e) {
        onClose()
        showError(e)
      }
    },
    [createUser, currentAccount?.id, showSuccess, t, onClose, showError]
  )

  const submitForm = useCallback(async () => {
    const isValid = trigger()
    if (isValid) {
      await handleSubmit(onSubmit)()
    }
  }, [handleSubmit, onSubmit, trigger])

  return (
    <MainDrawer
      onClose={onClose}
      body={
        <Box flexGrow={1} className='wrapper__content'>
          <Typography
            variant='h5'
            gutterBottom
            style={{
              fontWeight: 600,
              fontSize: '18px',
              lineHeight: '21px',
              color: 'var(--content-primary)',
              marginBottom: '16px',
            }}>
            {t('user.ADD_A_USER')}
          </Typography>
          <Box<'form'>
            component='form'
            noValidate
            autoComplete='off'
            display='flex'
            flexDirection='column'
            gap='16px'>
            <Box>
              <StyledInputLabel shrink htmlFor='user-form-name'>
                {t('user.NAME')}
              </StyledInputLabel>
              <StyledTextField
                fullWidth
                variant='outlined'
                size='small'
                id='user-form-name'
                {...register('name', {
                  required: t('user.NAME_REQUIRED'),
                })}
              />
            </Box>
            <Box>
              <StyledInputLabel shrink htmlFor='user-form-email'>
                {t('user.EMAIL')}
              </StyledInputLabel>
              <StyledTextField
                required
                id='element-form-csp-gcp-pairing-key'
                variant='outlined'
                size='small'
                {...register('email', {
                  required: t('users.EMAIL_REQUIRED'),
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: t('user.VALID_EMAIL_REQUIRED'),
                  },
                })}
              />
            </Box>
            <Box>
              <StyledFormControlLabel
                control={
                  <StyledCheckBox
                    {...register('profileId')}
                    value={UserProfileType.AccountAdmin}
                  />
                }
                label='is Admin'
                disableTypography={true}
              />
            </Box>
          </Box>
        </Box>
      }
      footer={
        <Box display='flex' gap='1%' flexGrow={1}>
          <StyledActionButton
            sx={{
              backgroundColor: 'var(--background-tertiary)',
              '&:hover': {
                backgroundColor: 'var(--background-secondary)',
              },
            }}
            color='primary'
            variant='text'
            onClick={onClose}>
            {t('element.DISCARD')}
          </StyledActionButton>
          <StyledActionButton
            type='submit'
            color='primary'
            variant='contained'
            disabled={isLoading}
            onClick={submitForm}>
            {t('user.ADD')}
          </StyledActionButton>
        </Box>
      }
    />
  )
}

export default AddUser
