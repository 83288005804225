// IMPORT DEPENDENCIES
import { Backdrop, CircularProgress } from '@mui/material'
import type { FC } from 'react'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { Route, Routes, useParams } from 'react-router-dom'

// IMPORT COMPONENTS
import useCurrentAccount from '../../../hooks/useCurrentAccount'
import {
  useDeleteNodeInWorkspaceV2Mutation,
  useDeleteTransportInWorkspaceV2Mutation,
  useGetWorkspaceByIdV2Query,
} from '../../../slices/workspacesApiSliceV2'
import SettingsTab from '../Tabs/SettingsTab'
import UsersTab from '../Tabs/UsersTab'
import ManageWorkspaceHeader from './ManageWorkspaceHeader'

// IMPORT STYLING
import { useTranslation } from 'react-i18next'
import { displayElementType } from '../../../hooks/displayElementType'
import { useSnackbar } from '../../../hooks/useSnackbar'
import type {
  AccessElement,
  CloudElement,
  ConcatElementV2,
  Kind,
  NodeType,
  TransportElementV2,
} from '../../../slices/types'
import '../style.scss'
import ViewTab from '../Tabs/ViewTab'

// TYPES
type ManageWorkspaceProps = {}

const displayTypeOfElement = (element: { kind?: Kind; type?: NodeType }) => {
  switch (element?.kind) {
    case 'transport':
      return element?.kind
    case 'node':
      return element?.type
    default:
      break
  }
}

const ManageWorkspace: FC<ManageWorkspaceProps> = () => {
  const { workspaceId, tabValue } = useParams<{
    workspaceId: string
    tabValue: string
  }>()
  const { showError, showSuccess } = useSnackbar()
  const { currentAccount } = useCurrentAccount()
  const [backdrop, setBackdrop] = useState<boolean>(true) // Backdrop Loader

  const [deleteNodeInWorkspace] = useDeleteNodeInWorkspaceV2Mutation()
  const [deleteTransportInWorkspace] = useDeleteTransportInWorkspaceV2Mutation()
  const { t } = useTranslation()

  const [orderedElements, setOrderedElements] = useState<
    (AccessElement | CloudElement | Omit<TransportElementV2, 'attachments'>)[]
  >([])

  // FETCH THE CURRENT WORKSPACE DATA
  const {
    data: workspaceData,
    isLoading,
    isSuccess,
    error,
  } = useGetWorkspaceByIdV2Query(
    {
      accountId: currentAccount?.id,
      workspaceId: workspaceId,
    },
    { skip: !currentAccount?.id, refetchOnMountOrArgChange: true }
  )

  useEffect(() => {
    if (!error) return
    showError(error)
  }, [showError, error])

  useEffect(() => {
    if (isLoading) setBackdrop(true)
    if (isSuccess) setBackdrop(false)
  }, [error, isLoading, isSuccess])

  useEffect(() => {
    setOrderedElements(
      [
        ...(workspaceData?.data.transports ?? []),
        ...(workspaceData?.data.nodes ?? []),
      ].sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
    )
  }, [workspaceData?.data.nodes, workspaceData?.data.transports])

  // DELETE ELEMENT
  const handleCloseDelete = useCallback(
    async (newValue?: boolean, element?: ConcatElementV2) => {
      setBackdrop(true)
      if (newValue) {
        try {
          if (!(element && 'kind' in element)) {
            //@refacto
            return
          }

          const elementType = 'type' in element ? element.type : ''
          switch (displayTypeOfElement(element)) {
            case 'cloud':
              await deleteNodeInWorkspace({
                accountId: currentAccount?.id,
                id: element.id,
                workspaceId: workspaceId,
              }).unwrap()
              setBackdrop(false)
              showSuccess(
                t('element.messages.ELEMENT_DELETED_WITH_SUCCESS', {
                  element: displayElementType(element?.kind, elementType, t),
                })
              )
              break
            case 'access':
              await deleteNodeInWorkspace({
                accountId: currentAccount?.id,
                id: element?.id,
                workspaceId: workspaceId,
              }).unwrap()
              setBackdrop(false)
              showSuccess(
                t('element.messages.ELEMENT_DELETED_WITH_SUCCESS', {
                  element: displayElementType(element?.kind, elementType, t),
                })
              )
              break
            case 'transport':
              await deleteTransportInWorkspace({
                accountId: currentAccount?.id,
                id: element?.id,
                workspaceId: workspaceId,
              }).unwrap()
              setBackdrop(false)
              showSuccess(
                t('element.messages.ELEMENT_DELETED_WITH_SUCCESS', {
                  element: displayElementType(element?.kind, elementType, t),
                })
              )
              break
          }
        } catch (e) {
          setBackdrop(false)
          showError(e)
        }
      }
    },
    [
      currentAccount?.id,
      deleteNodeInWorkspace,
      deleteTransportInWorkspace,
      t,
      workspaceId,
      showError,
      showSuccess,
    ]
  )

  // Display view according to tab
  const tabRender = useMemo(() => {
    switch (tabValue) {
      case 'view':
        return (
          <Routes>
            <Route
              path='/:viewKind/*'
              element={
                <ViewTab
                  workspaceData={workspaceData}
                  handleCloseDelete={handleCloseDelete}
                  orderedElements={orderedElements}
                />
              }
            />
          </Routes>
        )
      case 'users':
        return <UsersTab />
      case 'settings':
        return <SettingsTab />
    }
  }, [handleCloseDelete, orderedElements, tabValue, workspaceData])

  if (!workspaceData) return
  return (
    <Fragment>
      <Backdrop
        sx={{ color: 'var(--root-color-action)', zIndex: '9999' }}
        open={backdrop}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <ManageWorkspaceHeader label={workspaceData.data.name} />

      {tabRender}
    </Fragment>
  )
}
export default ManageWorkspace
