import { Box, Typography } from '@mui/material'
import { useCallback, type FC } from 'react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { MainDrawer } from '../../components/drawer/MainDrawer'
import {
  StyledCheckBox,
  StyledFormControlLabel,
} from '../../components/fields/StyledCheckBox'
import {
  StyledActionButton,
  StyledInputLabel,
  StyledTextField,
} from '../../components/forms/StyledFormComponents'
import useCurrentAccount from '../../hooks/useCurrentAccount'
import { useSnackbar } from '../../hooks/useSnackbar'
import { useSelfQuery } from '../../slices/authApiSlice'
import { selectCurrentToken } from '../../slices/authSlice'
import { UserProfileType } from '../../slices/types'
import {
  useGetUserByIdQuery,
  useUpdateUserMutation,
} from '../../slices/usersApiSlice'

interface AddUserProps {
  onClose: () => void
}

export interface EditFormInput {
  name: string
  isAdmin: boolean
}

const EditUser: FC<AddUserProps> = ({ onClose }) => {
  const { id } = useParams()
  const { t } = useTranslation()

  const { currentAccount } = useCurrentAccount()
  const [updateUser, { isLoading }] = useUpdateUserMutation()
  const { data: userData } = useGetUserByIdQuery(
    { accountId: currentAccount?.id, id },
    {
      skip: !currentAccount?.id || !id,
    }
  )

  const { register, handleSubmit, trigger } = useForm<EditFormInput>({
    shouldUseNativeValidation: true,
    defaultValues: {
      name: userData?.name,
    },
  })
  const token = useSelector(selectCurrentToken)
  const { data: meData } = useSelfQuery(null, {
    skip: !token,
  })

  const { showError, showSuccess } = useSnackbar()

  const onSubmit: SubmitHandler<EditFormInput> = useCallback(
    async (data) => {
      if (!currentAccount || !meData) {
        return
      }

      const { name, isAdmin } = data
      let user: Parameters<typeof updateUser>[0]['user'] = {
        id,
        name,
      }

      if (meData.id !== id) {
        user = {
          ...user,
          profileId: isAdmin
            ? UserProfileType.AccountAdmin
            : UserProfileType.AccountView,
        }
      }

      try {
        await updateUser({
          accountId: currentAccount?.id,
          user,
        }).unwrap()
        showSuccess(t('user.UPDATED_USER'))
      } catch (e) {
        showError(e)
      } finally {
        onClose()
      }
    },
    [id, meData, updateUser, currentAccount, showSuccess, t, showError, onClose]
  )

  const submitForm = useCallback(async () => {
    const isValid = trigger()
    if (isValid) {
      await handleSubmit(onSubmit)()
    }
  }, [handleSubmit, onSubmit, trigger])

  if (!userData) {
    return null
  }

  return (
    <MainDrawer
      onClose={onClose}
      body={
        <Box flexGrow={1} className='wrapper__content'>
          <Typography
            variant='h5'
            gutterBottom
            style={{
              fontWeight: 600,
              fontSize: '18px',
              lineHeight: '21px',
              color: 'var(--content-primary)',
              marginBottom: '16px',
            }}>
            {t('user.EDIT_USER')}
          </Typography>
          <Box<'form'>
            component='form'
            noValidate
            autoComplete='off'
            display='flex'
            flexDirection='column'
            gap='16px'>
            <Box>
              <StyledInputLabel shrink htmlFor='user-form-name'>
                {t('user.NAME')}
              </StyledInputLabel>
              <StyledTextField
                required
                id='user-form-name'
                variant='outlined'
                size='small'
                defaultValue={userData.name}
                {...register('name', {
                  required: t('user.NAME_REQUIRED'),
                })}
                slotProps={{
                  htmlInput: {
                    form: {
                      autocomplete: 'off',
                    },
                  },
                }}
              />
            </Box>
            <Box>
              {meData.id !== userData.id && (
                <StyledFormControlLabel
                  control={
                    <StyledCheckBox
                      {...register('isAdmin')}
                      defaultChecked={userData.isAdmin}
                      disabled={meData.id === userData.id}
                    />
                  }
                  label='is Admin'
                  disableTypography={true}
                />
              )}
            </Box>
          </Box>
        </Box>
      }
      footer={
        <Box display='flex' gap='1%' flexGrow={1}>
          <StyledActionButton
            sx={{
              backgroundColor: 'var(--background-tertiary)',
              '&:hover': {
                backgroundColor: 'var(--background-secondary)',
              },
            }}
            color='primary'
            variant='text'
            onClick={onClose}>
            {t('element.DISCARD')}
          </StyledActionButton>
          <StyledActionButton
            type='submit'
            color='primary'
            variant='contained'
            disabled={isLoading}
            onClick={submitForm}>
            {t('user.EDIT')}
          </StyledActionButton>
        </Box>
      }
    />
  )
}

export default EditUser
