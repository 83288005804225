import { Card, Toolbar } from '@mui/material'
import type { EdgeProps } from '@xyflow/react'
import {
  BaseEdge,
  EdgeLabelRenderer,
  getBezierPath,
  useNodesData,
} from '@xyflow/react'
import type { FC } from 'react'
import { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import DetachmentIcon from '../../../../../../assets/icons-io-v1/detachment.svg?react'
import ShowIcon from '../../../../../../assets/icons-io-v1/show.svg?react'

import { StyledIdCardIconButton } from '../../../../../../components/forms/SyledIconButtons'
import Visible from '../../../../../../components/permissions/Visible'
import { administrativeStateColor } from '../../../../../../hooks/administrativeStateColor'
import { ResourceType } from '../../../../../../slices/authorizationsApiSlice'
import type { ElementStates } from '../../../../../../slices/types'
import { disableAdministrativeState } from '../../../../../../utils/states'
import type { LogicalAttachmentEdge, LogicalNodes } from '../types'

interface AttachmentActionsProps {
  transform: string
  workspaceId: string
  administrativeState: ElementStates
  hide: boolean
  showDetails: () => void
  showDelete: () => void
}

const AttachmentActions: FC<AttachmentActionsProps> = ({
  transform,
  workspaceId,
  administrativeState,
  showDetails,
  showDelete,
  hide,
}) => {
  return (
    <Card
      className='nodrag nopan'
      style={{
        position: 'absolute',
        visibility: hide ? 'hidden' : 'visible',
        transform,
        pointerEvents: 'all',
      }}>
      <Toolbar>
        <Visible
          resource={ResourceType.Workspace}
          resourceId={workspaceId}
          action='get_attachment'>
          <div className='accordion__container-trash-icon'>
            <StyledIdCardIconButton
              disabled={disableAdministrativeState(administrativeState)}
              onClick={showDetails}>
              <ShowIcon />
            </StyledIdCardIconButton>
          </div>
        </Visible>
        <Visible
          resource={ResourceType.Workspace}
          resourceId={workspaceId}
          action='delete_attachment'>
          <div className='accordion__container-trash-icon'>
            <StyledIdCardIconButton
              disabled={disableAdministrativeState(administrativeState)}
              onClick={showDelete}>
              <DetachmentIcon stroke='black' fill='black' clip='black' />
            </StyledIdCardIconButton>
          </div>
        </Visible>
      </Toolbar>
    </Card>
  )
}

const Attachment: FC<EdgeProps<LogicalAttachmentEdge>> = ({
  id,
  markerEnd,
  markerStart,
  style = {},
  sourceX,
  sourceY,
  sourcePosition,
  targetX,
  targetY,
  targetPosition,
  data,
  selected,
}) => {
  const { administrativeState, transportId, nodeId } = data
  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  })
  const navigate = useNavigate()
  const { workspaceId } = useParams()

  const { data: dataNode } = useNodesData<LogicalNodes>(data.nodeId)

  const showDeleteTransport = useCallback(() => {
    navigate(`attachment/transport/${transportId}`)
  }, [navigate, transportId])
  const showDetails = useCallback(() => {
    navigate(`details/attachments/${id}`)
  }, [id, navigate])

  const showDeleteNode = useCallback(() => {
    if (dataNode.kind === 'node') {
      navigate(`attachment/${dataNode.type}/${nodeId}`)
    }
  }, [dataNode, navigate, nodeId])

  return (
    <>
      <BaseEdge
        id={id}
        key={id}
        path={edgePath}
        markerEnd={markerEnd}
        style={{
          ...style,
          stroke: administrativeStateColor(administrativeState),
          strokeWidth: selected ? '4' : '2',
          filter: selected
            ? `drop-shadow(0px 0px 10px ${administrativeStateColor(administrativeState)})`
            : '',
        }}
        markerStart={markerStart}
      />
      <EdgeLabelRenderer>
        <AttachmentActions
          transform={`translate(-50%, 20%) translate(${sourceX}px,${sourceY}px)`}
          workspaceId={workspaceId}
          administrativeState={administrativeState}
          hide={!selected}
          showDelete={showDeleteNode}
          showDetails={showDetails}
          key={'node'}
        />
        <AttachmentActions
          transform={`translate(-50%, -120%) translate(${targetX}px,${targetY}px)`}
          administrativeState={administrativeState}
          workspaceId={workspaceId}
          hide={!selected}
          showDelete={showDeleteTransport}
          showDetails={showDetails}
          key={'transport'}
        />
      </EdgeLabelRenderer>
    </>
  )
}

export default Attachment
