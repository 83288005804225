// -- Import NPM
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { SnackbarProvider } from 'notistack'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { HashRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

// -- Import components
import './i18n'
import { persistor, store } from './store'
import App from './views/App'

// -- Import Assets
import SnackbarError from './components/modals/SnackbarError'
import SnackbarProgress from './components/modals/SnackbarProgress'
import { SideBarProvider } from './hooks/useSideBar'
import './main.scss'

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: 'var(--font-family)',
  },
})

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <Provider store={store}>
      <SnackbarProvider
        autoHideDuration={4000}
        maxSnack={3}
        preventDuplicate={true}
        Components={{
          snackbarError: SnackbarError,
          snackbarProgress: SnackbarProgress,
        }}>
        <PersistGate persistor={persistor} loading={null}>
          <HashRouter>
            <ThemeProvider theme={theme}>
              <Suspense fallback={null}>
                <SideBarProvider>
                  <App />
                </SideBarProvider>
              </Suspense>
            </ThemeProvider>
          </HashRouter>
        </PersistGate>
      </SnackbarProvider>
    </Provider>
  </React.StrictMode>
)
