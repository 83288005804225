// -- NPM IMPORTS
import {
  Backdrop,
  CircularProgress,
  IconButton,
  InputLabel,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useEffect, useState } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

// -- COMPONENT IMPORTS
import { StyledCheckBox } from '../../components/fields/StyledCheckBox'
import {
  StyledActionButton,
  StyledTextField,
} from '../../components/forms/StyledFormComponents'
import {
  useCheckPasswordMutation,
  useCheckTokenValidityQuery,
  useSetPasswordMutation,
} from '../../slices/setPassWordAPISlice'

// -- ASSETS IMPORTS
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useDebounce } from '../../hooks/useDebounce'
import { useSnackbar } from '../../hooks/useSnackbar'
import PasswordStrengthMeter from './PasswordStrengthMeter'
import { DOCS_URL } from './SetPassword'
import './style.scss'
import TopLogo from './TopLogo'

interface formInput {
  password: string
  password_2: string
  cgu: boolean
}

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  color: 'var(--content-secondary)',
  fontSize: '18px',
  marginBottom: '0px',
}))

export default function ResetPassword() {
  const { showError, showSuccess, showErrorWithMessage } = useSnackbar()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { token } = useParams()

  const [checkPassword] = useCheckPasswordMutation()
  const [setPassword] = useSetPasswordMutation()

  const { register, handleSubmit, watch } = useForm<formInput>({
    mode: 'onBlur',
    shouldUseNativeValidation: true,
  })

  // ---------------------------- //
  // ----- PASSWORD STRENGTH ---- //
  const [password, setPasswordChanged] = useState('')
  const [strength, setStrength] = useState(0)
  const [errors, setErrors] = useState(null)
  const [showPassword, setShowPassword] = useState({
    password: false,
    password_2: false,
  })

  const debouncedValue = useDebounce(async (password: string) => {
    try {
      setErrors([])
      await checkPassword({ password: password }).unwrap()
    } catch (e) {
      if (e.status === 400) {
        setErrors(e?.data?.details)
      }
    }
  }, 250)

  const [activatedSubmitBtn, setActivateSubmitBtn] = useState<boolean>(true)
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'password') {
        setPasswordChanged(value?.password)
        debouncedValue(value?.password)
      }

      if (
        value.cgu &&
        strength === 100 &&
        value?.password === value?.password_2
      ) {
        setActivateSubmitBtn(false)
      } else {
        setActivateSubmitBtn(true)
      }
    })
    return () => subscription.unsubscribe()
  }, [debouncedValue, strength, watch])

  // ---------------------------- //
  // ----- PASSWORD STRENGTH ---- //

  // -------------------------------- //
  // ----- CHECK TOKEN VALIDITY ----- //
  const [formVisible, setFormVisible] = useState(false) // this hides the form while the token is being checked, avoids a glitch beofre the navigtion
  const { isSuccess, isError } = useCheckTokenValidityQuery(token)
  useEffect(() => {
    setLoading(true)

    if (isSuccess) {
      setLoading(false)
      setFormVisible(true)
    } else if (isError) {
      setLoading(false)
      navigate('/login', { state: { isExpired: true } })
    }
  }, [isSuccess, isError, navigate])
  // ----- CHECK TOKEN VALIDITY ----- //
  // -------------------------------- //

  const [loading, setLoading] = useState(false)

  const handleForm: SubmitHandler<formInput> = async (data) => {
    const { ...newData } = data

    try {
      await setPassword({
        token: token,
        password: newData?.password,
        cgu: newData?.cgu,
      }).unwrap()
      navigate('/login')
      showSuccess(t('login.PASSWORD_SET_SUCCESS'))
    } catch (error) {
      setLoading(false)
      showError(error)
    }
  }

  const handleClickShowPassword = (field: string) => {
    setShowPassword({ ...showPassword, [field]: !showPassword[field] })
  }

  return (
    <main className='login__main'>
      <Backdrop
        sx={{ color: 'var(--root-color-action)', zIndex: '9999' }}
        open={loading}>
        <CircularProgress color='inherit' />
      </Backdrop>

      <section className='login__main-container--center'>
        {formVisible && (
          <>
            <TopLogo />
            <div className='login__form-container--reset'>
              <h1>{t('login.HEADER_RESET_PASSWORD')}</h1>
              <form onSubmit={handleSubmit(handleForm)}>
                <div>
                  <div className='login__form-container--field'>
                    <StyledInputLabel shrink htmlFor='password'>
                      {t('login.NEW_PASSWORD')}
                    </StyledInputLabel>
                    <StyledTextField
                      required
                      id='password'
                      variant='outlined'
                      type={showPassword?.password ? 'text' : 'password'}
                      key='1'
                      autoFocus
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          paddingRight: '0',
                        },
                        '& .MuiInputBase-input': {
                          borderRight: 'none',
                        },
                      }}
                      slotProps={{
                        input: {
                          endAdornment: (
                            <IconButton
                              sx={{
                                backgroundColor: 'var(--background-secondary)',
                                border: '1px solid var(--border-primary)',
                                borderLeft: 'none',
                              }}
                              onClick={() =>
                                handleClickShowPassword('password')
                              }>
                              {showPassword?.password ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          ),
                        },
                        htmlInput: {
                          form: {
                            autocomplete: 'off',
                          },
                        },
                      }}
                      {...register('password', {
                        required: t('login.PASSWORD_REQUIRED'),
                      })}
                    />
                    <PasswordStrengthMeter
                      password={password}
                      strength={strength}
                      setStrength={setStrength}
                      errors={errors}
                    />
                  </div>
                  <div className='login__form-container--field'>
                    <StyledInputLabel shrink htmlFor='password-2'>
                      {t('login.CONFIRM_NEW_PASSWORD')}
                    </StyledInputLabel>
                    <StyledTextField
                      required
                      id='password-2'
                      variant='outlined'
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          paddingRight: '0',
                        },
                        '& .MuiInputBase-input': {
                          borderRight: 'none',
                        },
                      }}
                      type={showPassword?.password_2 ? 'text' : 'password'}
                      key='2'
                      slotProps={{
                        input: {
                          endAdornment: (
                            <IconButton
                              sx={{
                                backgroundColor: 'var(--background-secondary)',
                                border: '1px solid var(--border-primary)',
                                borderLeft: 'none',
                              }}
                              onClick={() =>
                                handleClickShowPassword('password_2')
                              }>
                              {showPassword?.password_2 ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          ),
                        },
                        htmlInput: {
                          form: {
                            autocomplete: 'off',
                          },
                        },
                      }}
                      {...register('password_2', {
                        required: t('login.PASSWORD_REQUIRED'),
                        validate: (val: string) => {
                          if (watch('password') !== val) {
                            setLoading(false)
                            showErrorWithMessage(t('login.PASSWORD_MATCH'))
                            return ''
                          }
                        },
                      })}
                    />
                  </div>
                  <div className='login__disclaimer--container'>
                    <StyledCheckBox
                      required
                      id='CGU'
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          paddingRight: '0',
                        },
                        '& .MuiInputBase-input': {
                          borderRight: 'none',
                        },
                      }}
                      key='3'
                      {...register('cgu')}
                    />
                    <div className='login__disclaimer'>
                      {t('disclaimers.SET_PASSWORD')}
                      <a
                        href={DOCS_URL}
                        className='login__disclaimer--link'
                        target='_blank'
                        rel='noopener noreferrer'>
                        {DOCS_URL}
                      </a>
                    </div>
                  </div>
                  <div className='login__form-container--field-button'>
                    <StyledActionButton
                      type='submit'
                      color='primary'
                      variant='contained'
                      disabled={activatedSubmitBtn}>
                      {t('login.RESET_PASSWORD_BUTTON')}
                    </StyledActionButton>
                  </div>
                </div>
              </form>
            </div>
          </>
        )}
      </section>
    </main>
  )
}
