// IMPORT DEPENDENCIES
import { Box, Tabs } from '@mui/material'
import { t } from 'i18next'
import type { FC, JSXElementConstructor, ReactElement } from 'react'
import { useCallback, useMemo } from 'react'
import {
  Link,
  NavLink,
  useMatch,
  useNavigate,
  useParams,
} from 'react-router-dom'

// IMPORT ICONS
import AddIcon from '../../../assets/icons-io-v1/add.svg?react'
import ChevronLeftIcon from '../../../assets/icons-io-v1/chevron_left_2.svg?react'
import UsersIcon from '../../../assets/icons-io-v1/group_2.svg?react'
import SettingsIcon from '../../../assets/icons-io-v1/settings.svg?react'
import ViewIcon from '../../../assets/icons-io-v1/show.svg?react'

// IMPORT COMPONENTS
import {
  StyledButton,
  StyledTab,
} from '../../../components/forms/StyledFormComponents'
import Visible from '../../../components/permissions/Visible'
import { ResourceType } from '../../../slices/authorizationsApiSlice'

// TYPES
type ManageWorksapceHeaderProps = {
  label: string
}

interface VisibleTabProps {
  resource: ResourceType
  resourceId: string
  action: string
  to: string
  value: string
  label: string
  icon: string | ReactElement<unknown, string | JSXElementConstructor<unknown>>
}

const VisibleTab: FC<VisibleTabProps> = ({
  resource,
  action,
  resourceId,
  label,
  icon,
  to,
  value,
}) => {
  return (
    <Visible resource={resource} resourceId={resourceId} action={action}>
      <StyledTab
        LinkComponent={NavLink}
        label={label}
        icon={icon}
        iconPosition='start'
        to={to}
        value={value}
      />
    </Visible>
  )
}

export default function ManageWorkspaceHeader({
  label,
}: ManageWorksapceHeaderProps) {
  const { workspaceId, tabValue } = useParams<{
    workspaceId: string
    tabValue: string
  }>()
  const navigate = useNavigate()

  // const matchesTab = useMatch('/workspaces/:workspaceId/:tabValue/*')
  const matchesTabs = useMatch('/workspaces/:workspaceId/:tabValue/*')
  const matchesViewKind = useMatch(
    '/workspaces/:workspaceId/:tabValue/:viewKind/*'
  )
  const matchesWorkspace = useMatch('/workspaces/:workspaceId/*')

  // DEFINES TABS AVAILABLES ROUTES (the useMemo avoids répitition for the useEffect)
  const routes = useMemo(
    () => [
      matchesWorkspace.pathnameBase + '/view/list',
      matchesWorkspace.pathnameBase + '/users',
      matchesWorkspace.pathnameBase + '/settings',
    ],
    [matchesWorkspace.pathnameBase]
  )

  const handleCreateElement = useCallback(() => {
    if (matchesViewKind) navigate(`${matchesViewKind.pathnameBase}/create`)
  }, [matchesViewKind, navigate])

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'var(--border-menu)' }}>
        <div className='workspace__page'>
          <div className='workspace__page__header'>
            <div className='workspace__page__header-content'>
              <span className='workspace__page__header-content'>
                <Link
                  to='/workspaces'
                  className='workspace__page__header-content-link'>
                  <ChevronLeftIcon />
                </Link>
              </span>
              <span className='workspace__page__header-content-name'>
                {label}
              </span>
            </div>
          </div>
          <div className='workspace__page__subheader'>
            {tabValue === 'view' && (
              <Visible
                resource={ResourceType.Workspace}
                resourceId={workspaceId}
                action='add_node'>
                <StyledButton
                  variant='outlined'
                  startIcon={<AddIcon />}
                  onClick={handleCreateElement}>
                  {t('design.ADD_ELEMENT')}
                </StyledButton>
              </Visible>
            )}

            <Tabs
              value={
                tabValue === 'view'
                  ? matchesTabs.pathnameBase + '/list'
                  : matchesTabs.pathnameBase
              }
              variant='fullWidth'
              sx={{
                padding: '0 40px',
                height: '100%',
                '& .MuiTabs-scroller': {
                  display: 'flex',
                  alignItems: 'center',
                },
              }}>
              <StyledTab
                LinkComponent={NavLink}
                label={t('workspace.VIEW')}
                icon={<ViewIcon />}
                iconPosition='start'
                to={routes[0]}
                value={routes[0]}
              />
              <VisibleTab
                resource={ResourceType.Workspace}
                resourceId={workspaceId}
                action={'list_workspace_users'}
                to={routes[1]}
                value={routes[1]}
                icon={<UsersIcon />}
                label={t('workspace.USERS')}
              />
              <VisibleTab
                resource={ResourceType.Workspace}
                resourceId={workspaceId}
                action={'update_workspace'}
                to={routes[2]}
                value={routes[2]}
                icon={<SettingsIcon />}
                label={t('workspace.SETTINGS')}
              />
            </Tabs>
          </div>
        </div>
      </Box>
    </>
  )
}
