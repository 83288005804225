import { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ListView from '../../../assets/icons-io-v1/listView.svg?react'
import MapView from '../../../assets/icons-io-v1/mapView.svg?react'
import { StyledMapIconButton } from '../../../components/forms/SyledIconButtons'

export default function MapViewSwitcher() {
  const navigate = useNavigate()
  const { workspaceId, viewKind } = useParams()

  const handleClickMap = useCallback(() => {
    navigate(`/workspaces/${workspaceId}/view/map`)
  }, [navigate, workspaceId])
  const handleClickList = useCallback(() => {
    navigate(`/workspaces/${workspaceId}/view/list`)
  }, [navigate, workspaceId])

  return (
    <>
      <StyledMapIconButton
        variant={viewKind === 'list' ? 'contained' : 'outlined'}
        // disabled={viewKind === 'list'}
        onClick={handleClickList}>
        <ListView />
      </StyledMapIconButton>
      <StyledMapIconButton
        variant={viewKind === 'map' ? 'contained' : 'outlined'}
        // disabled={viewKind === 'map'}
        onClick={handleClickMap}>
        <div
          style={{
            width: 40,
            position: 'absolute',
            top: 3,
            left: -10,
            fontSize: '7px',
            textTransform: 'uppercase',
            transform: 'rotate(-45deg)',
            background: 'var(--root-color-basic-brand-alto)',
            color: 'var(--root-color-grey-10)',
          }}>
          beta
        </div>
        <MapView />
      </StyledMapIconButton>
    </>
  )
}
