import type { Edge, EdgeTypes, Node, NodeTypes } from '@xyflow/react'
import {
  Background,
  Controls,
  MiniMap,
  Panel,
  ReactFlow,
  useNodesInitialized,
  useReactFlow,
} from '@xyflow/react'
import type { FC } from 'react'
import { useEffect } from 'react'
import { administrativeStateColor } from '../../../../../../hooks/administrativeStateColor'
import type { LogicalNodes } from '../types'
import { LogicalEdgeType, LogicalNodeType } from '../types'
import useFlowStore from './hooks/useFlowStore'

import { ButtonGroup, MenuItem } from '@mui/material'
import '@xyflow/react/dist/style.css'
import { useThemeSwitcher } from 'react-css-theme-switcher'
import { useTranslation } from 'react-i18next'
import { StyledSelectMenu } from '../../../../../../components/forms/StyledFormComponents'
import MapViewSwitcher from '../../../../Components/MapViewSwitcher'
import Attachment from '../Edges/Attachment'
import PhysicalPortLink from '../Edges/PhysicalPortLink'
import ServiceProviderLink from '../Edges/ServiceProviderLink'
import AccessNode from '../Nodes/AccessNode'
import CloudNode from '../Nodes/CloudNode'
import PhysicalPort from '../Nodes/PhysicalPort'
import CloudProvider from '../Nodes/ServiceProviderNode'
import Transport from '../Nodes/Transport'
import useLayoutForm from './hooks/useLayoutForm'
import useLogicalLayout from './hooks/useLogicalLayout'
import './style.scss'

interface FlowProps<
  NodeType extends Node = Node,
  EdgeType extends Edge = Edge,
> {
  initialNodes?: NodeType[]
  initialEdges?: EdgeType[]
}

export const nodeTypes: NodeTypes = {
  [LogicalNodeType.Cloud]: CloudNode,
  [LogicalNodeType.Access]: AccessNode,
  [LogicalNodeType.Transport]: Transport,
  [LogicalNodeType.PhysicalPort]: PhysicalPort,
  [LogicalNodeType.ServiceProvider]: CloudProvider,
}

export const edgesTypes: EdgeTypes = {
  [LogicalEdgeType.Attachment]: Attachment,
  [LogicalEdgeType.Physical]: PhysicalPortLink,
  [LogicalEdgeType.ServiceProvider]: ServiceProviderLink,
}

export const Flow: FC<FlowProps> = () => {
  const { edges, nodes, onEdgesChange, onNodesChange } = useFlowStore()
  const { currentTheme } = useThemeSwitcher()

  const { fitView, setCenter, updateNode } = useReactFlow()

  const nodesInitialized = useNodesInitialized()

  useLogicalLayout()

  const { handleChangeDirection } = useLayoutForm()
  const { t } = useTranslation()

  useEffect(() => {
    let id: number
    if (nodesInitialized) {
      id = requestAnimationFrame(() => {
        fitView()
      })
    }
    return () => {
      cancelAnimationFrame(id)
    }
  }, [fitView, nodesInitialized])

  return (
    <ReactFlow
      colorMode={currentTheme === 'dark' ? currentTheme : 'light'}
      nodes={nodes}
      onNodesChange={onNodesChange}
      edges={edges}
      onEdgesChange={onEdgesChange}
      fitView
      minZoom={0.25}
      maxZoom={1}
      nodeTypes={nodeTypes}
      nodesFocusable
      nodesConnectable={false}
      nodesDraggable
      edgeTypes={edgesTypes}>
      <Background />
      <Panel position='top-left'>
        <ButtonGroup
          style={{
            gap: '0.5rem',
          }}>
          <StyledSelectMenu
            onChange={handleChangeDirection}
            defaultValue={'RIGHT'}>
            <MenuItem value='DOWN'>{t('actions.menu.VERTICAL')}</MenuItem>
            <MenuItem value='RIGHT'>{t('actions.menu.HORIZONTAL')}</MenuItem>
          </StyledSelectMenu>
        </ButtonGroup>
      </Panel>
      <Panel position='top-right'>
        <ButtonGroup
          style={{
            gap: '0.5rem',
          }}>
          <MapViewSwitcher />
        </ButtonGroup>
      </Panel>
      <Controls />
      <MiniMap
        nodeColor={({ data: { administrativeState } }: LogicalNodes) =>
          `${administrativeStateColor(administrativeState)}`
        }
        nodeStrokeColor={({ data: { administrativeState } }: LogicalNodes) =>
          `${administrativeStateColor(administrativeState)}`
        }
        nodeBorderRadius={5}
        nodeStrokeWidth={30}
        onNodeClick={(_, { id, position, width, height }) => {
          const x = position.x + width / 2
          const y = position.y + height / 2
          setCenter(x, y, { zoom: 1 })
          updateNode(id, { selected: true })
        }}
      />
    </ReactFlow>
  )
}

export default Flow
