import type { FC } from 'react'
import { useMemo } from 'react'
import { Route, Routes, useParams } from 'react-router-dom'
import DrawerAttachment from '../../../../components/drawer/drawerAttachment/DrawerAttachment'
import DrawerCreate from '../../../../components/drawer/DrawerCreateElementV2'
import DrawerDelete from '../../../../components/drawer/DrawerDeleteV2'
import DrawerEdit from '../../../../components/drawer/DrawerEditV2'
import DrawerReadOnly from '../../../../components/drawer/DrawerReadOnly'
import type {
  AccessElement,
  CloudElement,
  TransportElementV2,
  WorkspaceV2,
} from '../../../../slices/types'
import List from './List'
import Logical from './Logical'
import { LayoutFormProvider } from './Logical/Flow/hooks/useLayoutForm'

interface ViewTabProps {
  workspaceData: WorkspaceV2
  handleCloseDelete: (
    newValue?: boolean,
    element?: {
      id?: string
    }
  ) => Promise<void>
  orderedElements: (
    | AccessElement
    | CloudElement
    | Omit<TransportElementV2, 'attachments'>
  )[]
}
const ViewTab: FC<ViewTabProps> = ({
  workspaceData,
  handleCloseDelete,
  orderedElements,
}) => {
  const { viewKind } = useParams<'viewKind'>()

  const view = useMemo(() => {
    switch (viewKind) {
      case 'map':
        return (
          <LayoutFormProvider>
            <Logical />
          </LayoutFormProvider>
        )
      case 'list':
        return <List elements={orderedElements} workspaceData={workspaceData} />
    }
  }, [orderedElements, viewKind, workspaceData])

  return (
    <>
      {view}
      <Routes>
        <Route path='/create/*' element={<DrawerCreate />} />
        <Route
          path='/details/:type/:elementId/:detailsTabView'
          element={<DrawerReadOnly workspaceData={workspaceData} />}
        />
        <Route
          path='/delete/:type/:elementId'
          element={<DrawerDelete handleCloseDelete={handleCloseDelete} />}
        />
        <Route path='/edit/:type/:elementId' element={<DrawerEdit />} />

        <Route
          path='/attachment/:type/:elementId/:location?'
          element={<DrawerAttachment workspaceData={workspaceData} />}
        />
      </Routes>
    </>
  )
}

export default ViewTab
