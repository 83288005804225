// -- Import NPM
import { useTranslation } from 'react-i18next'

// -- Import Components

// -- Import Assets

import type { FC } from 'react'
import './style.scss'

type NotFoundProps = {}

const NotFound: FC<NotFoundProps> = () => {
  const { t } = useTranslation()

  return (
    <div className='not-found__container'>
      <h1>{t('messages.notfound.MAIN_MESSAGE')}</h1>
    </div>
  )
}

export default NotFound
