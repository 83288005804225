// -- Import NPMS
import {
  AccordionSummary,
  Backdrop,
  Button,
  CircularProgress,
  IconButton,
  styled,
} from '@mui/material'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

// -- Import Components
import DrawerWorkspace from '../../../components/drawer/DrawerWorkspace'
import { StyledAccordion } from '../../../components/forms/StyledFormComponents'
import CreateItemModal from '../../../components/modals/CreateItemModal'
import DeleteModal from '../../../components/modals/DeleteModal'
import Visible from '../../../components/permissions/Visible'
import useCurrentAccount from '../../../hooks/useCurrentAccount'
import { useSelfQuery } from '../../../slices/authApiSlice'
import {
  ResourceType,
  useGetProfilesInWorkspaceQuery,
} from '../../../slices/authorizationsApiSlice'
import type { User } from '../../../slices/types'
import {
  useDeleteUserProfileInWorkspaceV2Mutation,
  useGetWorkspaceAllowedUsersV2Query,
  useGetWorkspaceByIdV2Query,
} from '../../../slices/workspacesApiSliceV2'
import UserAvatar from '../../User/Avatar'
import AddUserProfile from '../UserProfile/Add'
import NoElementYet from './NoElementYet'

// -- Import Assets
import TrashIcon from '../../../assets/icons-16/trash.svg?react'
import { useSnackbar } from '../../../hooks/useSnackbar'

export default function UsersTab() {
  const { showError, showSuccess } = useSnackbar()
  const { t } = useTranslation()
  const { currentAccount } = useCurrentAccount()
  const { workspaceId } = useParams()
  const [, setItemId] = useState(null)
  const { data: profiles } = useGetProfilesInWorkspaceQuery({
    accountId: currentAccount?.id,
    workspaceId: workspaceId,
  })

  const { data: workspaceData } = useGetWorkspaceByIdV2Query({
    accountId: currentAccount?.id,
    workspaceId: workspaceId,
  })

  const { data: allowedUsers, isSuccess: userSuccess } =
    useGetWorkspaceAllowedUsersV2Query({
      accountId: currentAccount?.id,
      workspaceId: workspaceId,
    })

  const { data: MeData } = useSelfQuery(null)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [pageView, setPageView] = useState('')
  const [openCreateModal, setCreateOpenModal] = useState(false) // state for the create new element modal
  const [rowData, setRowData] = useState(null)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [loading, setLoading] = useState(true)

  // This useEffect checks that the Data[] is loaded and sets the backdrop Loader to false
  useEffect(() => {
    if (userSuccess) setLoading(false)
  }, [userSuccess])
  // ----- SNACKBAR CONTROL ----- //
  // ---------------------------- //

  const handleClickOpenDeleteModal = useCallback(
    (
      e: { preventDefault: () => void; stopPropagation: () => void },
      row: User
    ) => {
      e.preventDefault()
      e.stopPropagation()
      setRowData(row)
      setOpenDeleteModal(true)
    },
    []
  )

  const [deleteUserInWorkspace] = useDeleteUserProfileInWorkspaceV2Mutation()

  const handleClose = useCallback(
    async (newValue?: boolean, row?: User) => {
      setOpenDeleteModal(false)
      setLoading(true)
      if (newValue) {
        try {
          await deleteUserInWorkspace({
            accountId: currentAccount?.id,
            workspaceId: workspaceId,
            userId: row?.id,
          }).unwrap()
          setLoading(false)
          showSuccess(t('user.DELETED_WS_USER'))
        } catch (e) {
          setLoading(false)
          showError(e)
        }
      }
    },
    [
      currentAccount?.id,
      deleteUserInWorkspace,
      t,
      workspaceId,
      showError,
      showSuccess,
    ]
  )

  const handleClickAddButton = (type: string, elementNature: string = '') => {
    switch (type) {
      case 'addUsers':
        setOpenDrawer(true)
        setPageView('addUsers')
        setItemId(workspaceId)
        break
      default:
        break
    }
  }

  const isMySelf = (user) => {
    return user.id === MeData.id
  }

  const StyledIconButton = styled(IconButton)(({ theme }) => ({
    '&.MuiIconButton-root': {
      color: 'var(--content-primary)',
    },
    '&.Mui-disabled': {
      color: 'var(--content-inactive)',
      opacity: 0.5,
    },
    '&.Mui-disabled:hover': { background: theme.palette.secondary.main },
  }))

  return (
    <Fragment>
      <Backdrop
        sx={{ color: 'var(--root-color-action)', zIndex: '9999' }}
        open={loading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      {workspaceData && (
        <>
          <div className='workspace__container--tabs'>
            <form className='workspace__container'>
              <div className='workspace__action'>
                <div className='workspace__form-action--btn'>
                  <Visible
                    resource={ResourceType.Workspace}
                    resourceId={workspaceId}
                    action='add_workspace_user'>
                    <Button
                      variant='contained'
                      onClick={() => handleClickAddButton('addUsers')}>
                      Add user
                    </Button>
                  </Visible>
                </div>
              </div>
              {allowedUsers?.length === 0 && (
                <NoElementYet
                  handleClickButtonBanner={() =>
                    handleClickAddButton('addUsers')
                  }
                  noElementMessage={'There are no users'}
                  noElementButtonText={'Add first user'}
                />
              )}
              {allowedUsers &&
                allowedUsers.map((user: User) => {
                  return (
                    <StyledAccordion
                      key={user?.id}
                      disableGutters={true}
                      className='accordion__container-accordion'
                      defaultExpanded={false}>
                      <div className='accordion__container-summary-wrapper'>
                        <AccordionSummary className='accordion__container-summary workspace__card-container'>
                          <div className=''>
                            <UserAvatar userName={user?.name} />
                          </div>
                          <div className='accordion__container-space-between'>
                            <div className='accordion__element-profile user__accordion--column'>
                              <span>{user?.name}</span>
                              <span className='workspace__card-last-edited'>
                                {user?.email}
                              </span>
                            </div>

                            <div className='accordion__element-state user__accordion--column'>
                              <span className='workspace__card-last-edited'>
                                {t('user.PROFILE')}
                              </span>
                              <span>
                                {t(`user.${user?.profile.toUpperCase()}`)}
                              </span>
                            </div>
                          </div>
                        </AccordionSummary>
                        <div className='user__accordion--trash-icon'>
                          <Visible
                            resource={ResourceType.Workspace}
                            resourceId={workspaceId}
                            action={'remove_workspace_user'}>
                            <StyledIconButton
                              onClick={(e) =>
                                handleClickOpenDeleteModal(e, user)
                              }
                              disabled={isMySelf(user)}>
                              <TrashIcon />
                            </StyledIconButton>
                          </Visible>
                        </div>
                      </div>
                    </StyledAccordion>
                  )
                })}
            </form>
          </div>
          <DrawerWorkspace
            pageView={pageView}
            setOpenDrawer={setOpenDrawer}
            openDrawer={openDrawer}
            addUserProfile={
              <AddUserProfile
                setOpenDrawer={setOpenDrawer}
                profiles={profiles}
                workspaceId={workspaceId}
                setLoading={setLoading}
              />
            }
          />
          <CreateItemModal
            openModal={openCreateModal}
            setOpenModal={setCreateOpenModal}
            model={t('model.USER')}
          />

          <DeleteModal
            element={rowData}
            handleClose={handleClose}
            openDeleteModal={openDeleteModal}
            setOpenDeleteModal={setOpenDeleteModal}
            deleteDialogMessage={t('modal.CONFIRM_USER_DELETION', {
              username: rowData?.name,
            })}
          />
        </>
      )}
    </Fragment>
  )
}
