// -- NPM IMPORTS
import {
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  CircularProgress,
  IconButton,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import moment from 'moment'
import type { FC } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

// -- COMPONENT IMPORTS
import PageHeader from '../../components/header/PageHeader'
import DeleteModal from '../../components/modals/DeleteModal'
import Visible from '../../components/permissions/Visible'
import { DisplayElementIcon } from '../../hooks/displayIcons'
import useCurrentAccount from '../../hooks/useCurrentAccount'
import { usePermissions } from '../../hooks/usePermissions'
import { useSelfQuery } from '../../slices/authApiSlice'
import { selectCurrentToken } from '../../slices/authSlice'
import { ResourceType } from '../../slices/authorizationsApiSlice'
import type { TransportElementV2, WorkspaceData } from '../../slices/types'
import { NodeType } from '../../slices/types'
import { useDeleteWorkspaceV2Mutation } from '../../slices/workspacesApiSliceV2'

// -- ASSETS IMPORTS
import TrashIcon from '../../assets/icons-16/trash.svg?react'
import {
  StyledAccordion,
  StyledBadge,
  StyledButton,
} from '../../components/forms/StyledFormComponents'
import { useSnackbar } from '../../hooks/useSnackbar'
import { useGetWorkspacesV2Query } from '../../slices/workspacesApiSliceV2'
import NoElementYet from './Tabs/NoElementYet'
import './style.scss'

type WorkspacesListProps = {}

const WorkspacesList: FC<WorkspacesListProps> = () => {
  const { showError, showSuccess } = useSnackbar()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { currentAccount, error } = useCurrentAccount()
  const [elementData, setElementData] = useState(null)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [deleteWorkspace] = useDeleteWorkspaceV2Mutation()

  const [loading, setLoading] = useState(true)

  // --------------------------------------------------------
  // ----- Get Self Data, and fix refetchOnMount issue -----
  const token = useSelector(selectCurrentToken)
  const [tokenState, setTokenState] = useState(token)
  const { refetch } = useSelfQuery(null, {
    skip: !token,
  })

  useEffect(() => {
    if (tokenState !== token) {
      refetch()
      setTokenState(token)
    }
  }, [tokenState, token, refetch])
  // ----- END Get Self Data, and fix refetchOnMount issue -----
  // -----------------------------------------------------------

  const { allowed: allowedListWorkspaces } = usePermissions(
    ResourceType.Account,
    currentAccount?.id,
    'list_workspaces'
  )

  const {
    data: workspacesData,
    error: errorWsData,
    isSuccess,
    isLoading,
  } = useGetWorkspacesV2Query(currentAccount?.id, {
    skip: !currentAccount?.id || !allowedListWorkspaces,
    refetchOnMountOrArgChange: true,
  })

  useEffect(() => {
    const err = error || errorWsData
    if (!err) return
    showError(error || errorWsData)
  }, [showError, error, errorWsData])

  // This useEffect checks that the Data[] is loaded and sets the backdrop Loader to false
  useEffect(() => {
    if (isLoading) setLoading(true)
    if (isSuccess) setLoading(false)
  }, [isLoading, isSuccess])

  const StyledIconButton = styled(IconButton)(({ theme }) => ({
    '&.MuiIconButton-root': {
      color: 'var(--content-primary)',
    },
    '&.Mui-disabled': {
      color: 'var(--content-inactive)',
      opacity: 0.5,
    },
    '&.Mui-disabled:hover': { background: theme.palette.secondary.main },
  }))

  const handleCardClickV2 = useCallback(
    (workspaceId: string) => {
      navigate(`${workspaceId}/view/list`)
    },
    [navigate]
  )

  const handleDeleteModal = (data) => {
    setElementData(data)
    setOpenDeleteModal(true)
  }

  const handleClose = useCallback(
    async (newValue?: boolean, element?: { id: string }) => {
      setOpenDeleteModal(false)
      setLoading(true)
      if (newValue) {
        try {
          await deleteWorkspace({
            accountId: currentAccount?.id,
            id: element?.id,
          }).unwrap()
          setLoading(false)
          showSuccess(
            t('workspace.messages.WORKSPACE_HAS_BEEN_DELETED_WITH_SUCCESS')
          )
        } catch (e) {
          setLoading(false)
          showError(e)
        }
      }
    },
    [currentAccount?.id, deleteWorkspace, t, showSuccess, showError]
  )

  const displayCurrencyPrice = (price) => {
    return new Intl.NumberFormat('eu-EU', {
      style: 'currency',
      currency: 'EUR',
      compactDisplay: 'short',
    }).format(price)
  }

  if (!currentAccount) return
  return (
    <>
      <Backdrop
        sx={{ color: 'var(--root-color-action)', zIndex: '9999' }}
        open={loading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      {!error && (
        <div className='workspace__container'>
          <PageHeader
            page='WORKSPACES'
            addItemFor={t('workspace.ADD_A_WORKSPACE')}
            permCreate={[
              ResourceType.Account,
              currentAccount?.id,
              'create_workspace',
            ]}
          />

          {workspacesData?.data?.length === 0 && (
            <NoElementYet noElementMessage={'There is no workspace.'} />
          )}

          {workspacesData &&
            workspacesData?.data?.map((workspace: WorkspaceData) => {
              const countGCPCloud = workspace?.nodes?.filter((el) => {
                return (
                  el?.type === NodeType.Cloud && el?.product?.cspName === 'GCP'
                )
              }).length

              const countAzureCloud = workspace?.nodes?.filter((el) => {
                return (
                  el?.type === NodeType.Cloud &&
                  el?.product?.cspName === 'AZURE'
                )
              }).length

              const countAwsCloud = workspace?.nodes?.filter((el) => {
                return (
                  el?.type === NodeType.Cloud && el?.product?.cspName === 'AWS'
                )
              }).length

              const countAccess = workspace?.nodes?.filter(
                (el) => el.type === NodeType.Access
              ).length

              const countTransport = (
                workspace?.transports as TransportElementV2[]
              )?.filter(() => {
                return true
              }).length

              return (
                <StyledAccordion
                  key={workspace?.id}
                  disableGutters={true}
                  defaultExpanded={false}>
                  <div className='accordion__container-summary-wrapper'>
                    <AccordionSummary className='accordion__container-summary workspace__card-container'>
                      <div className='accordion__container-space-between'>
                        <div className='accordion__container-show'>
                          <div className='accordion__element-details'>
                            <div className='accordion__element-details-container accordion__element-details-container--ws-icons'>
                              <div className='accordion__element-workspace-card'>
                                <div className='workspace__card-name'>
                                  {workspace?.name}
                                </div>
                                <div className='workspace__card-description'>
                                  {workspace?.description}
                                </div>
                              </div>
                              <div className='workspace__card-container--icons'>
                                {countGCPCloud > 0 ? (
                                  <StyledBadge badgeContent={countGCPCloud}>
                                    <DisplayElementIcon
                                      kind='node'
                                      type='cloud'
                                      csp='GCP'
                                    />
                                  </StyledBadge>
                                ) : (
                                  ''
                                )}
                                {countAzureCloud > 0 ? (
                                  <StyledBadge badgeContent={countAzureCloud}>
                                    <DisplayElementIcon
                                      kind='node'
                                      type='cloud'
                                      csp='AZURE'
                                    />
                                  </StyledBadge>
                                ) : (
                                  ''
                                )}
                                {countAwsCloud > 0 ? (
                                  <StyledBadge badgeContent={countAwsCloud}>
                                    <DisplayElementIcon
                                      kind='node'
                                      type='cloud'
                                      csp='AWS'
                                    />
                                  </StyledBadge>
                                ) : (
                                  ''
                                )}
                                {countAccess > 0 ? (
                                  <StyledBadge badgeContent={countAccess}>
                                    <DisplayElementIcon
                                      kind='node'
                                      type='access'
                                    />
                                  </StyledBadge>
                                ) : (
                                  ''
                                )}
                                {countTransport > 0 ? (
                                  <StyledBadge badgeContent={countTransport}>
                                    <DisplayElementIcon kind='transport' />
                                  </StyledBadge>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='accordion__container-price'>
                          <span className='workspace__card-price'>
                            {t('element.MRC')} :{' '}
                          </span>
                          <span>
                            {workspace?.price ? (
                              <>
                                {displayCurrencyPrice(workspace?.price)}{' '}
                                {/* <span>/month</span> */}
                              </>
                            ) : (
                              ' - '
                            )}
                          </span>
                        </div>
                      </div>
                    </AccordionSummary>
                    <div className='accordion__element-state--trash-icon workspace__card-trash-icon'>
                      <StyledButton
                        onClick={() => handleCardClickV2(workspace?.id)}
                        variant='outlined'>
                        View
                      </StyledButton>
                      <Visible
                        resource={ResourceType.Workspace}
                        resourceId={workspace?.id}
                        action={'delete_workspace'}>
                        <StyledIconButton
                          onClick={() => handleDeleteModal(workspace)}
                          disabled={
                            workspace?.nodes?.length > 0 ||
                            workspace?.transports?.length > 0
                          }>
                          <TrashIcon />
                        </StyledIconButton>
                      </Visible>
                    </div>
                  </div>

                  {/* /////////// DETAILS /////////// */}
                  <AccordionDetails>
                    <div className='accordion__container-open'>
                      <div className='accordion__container-open--details'>
                        <div className='accordion__open--details-title'>
                          {t('element.LAST_EDITED')}
                        </div>
                        <div className='accordion__open--details-info accordion__open--details-info-PORTS'>
                          {workspace?.updatedAt
                            ? moment(workspace?.updatedAt).format('DD-MM-YYYY')
                            : ' - '}
                        </div>
                      </div>

                      <div className='accordion__container-open--details'>
                        <div className='accordion__open--details-title'>
                          {t('workspace.USERS')}
                        </div>
                        <div className='accordion__open--details-info accordion__open--details-info-PORTS'>
                          {workspace?.usersCount
                            ? workspace?.usersCount
                            : '  -'}
                        </div>
                      </div>

                      <div className='accordion__container-open--details'>
                        <div className='accordion__open--details-title'>
                          {t('workspace.ID')}
                        </div>
                        <div className='accordion__open--details-info accordion__open--details-info-PORTS'>
                          {workspace?.id}
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                </StyledAccordion>
              )
            })}
        </div>
      )}
      <DeleteModal
        element={elementData}
        handleClose={handleClose}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        deleteDialogMessage={t('modal.CONFIRM_ELEMENT_DELETION', {
          element: elementData?.name,
        })}
      />
    </>
  )
}

export default WorkspacesList
