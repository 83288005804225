import type { FC } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Workspaces from '../Workspace/index'
import ManageWorkspace from '../Workspace/ManageV2/ManageWorkspace'

import RequireAuth from '../App/RequireAuth'
import Dashboard from '../Dashboard/index'
import NotFound from '../NotFound'
import Ports from '../Ports/index'
import Settings from '../Settings/index'
import GeneralSettings from '../Settings/Tabs/general'
import SecuritySettings from '../Settings/Tabs/security'
import TokenSettings from '../Settings/Tabs/TokenSettings/TokenSettings'
import Users from '../User/index'

type LayoutProps = {}

const Layout: FC<LayoutProps> = () => {
  return (
    <Routes>
      <Route element={<RequireAuth />}>
        <Route path='/' element={<Navigate to='/dashboard' replace />} />
        <Route index path='/dashboard' element={<Dashboard />} />
        <Route path='/workspaces' element={<Workspaces />} />
        <Route
          path='/workspaces/:workspaceId/:tabValue/*'
          element={<ManageWorkspace />}
        />
        <Route path='/ports/*' element={<Ports />} />
        <Route path='/users/*' element={<Users />} />
        <Route path='/settings' element={<Settings />}>
          <Route index element={<Navigate to={'general'} replace={true} />} />
          <Route path='general/*' element={<GeneralSettings />} />
          <Route path='security/*' element={<SecuritySettings />} />
          <Route path='tokens/*' element={<TokenSettings />} />
        </Route>
        <Route path='/*' element={<NotFound />} />
      </Route>
    </Routes>
  )
}

export default Layout
