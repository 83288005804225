import { Avatar, Card, CardContent, CardHeader, Toolbar } from '@mui/material'
import type { NodeProps } from '@xyflow/react'
import { Handle, NodeToolbar, Position } from '@xyflow/react'
import type { FC } from 'react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { StyledLVCard } from '../../../../../../components/cards/styledLogicalViewCard'
import OperationalStatus from '../../../../../../components/chips/operationalStatus'
import { StyledIdCardIconButton } from '../../../../../../components/forms/SyledIconButtons'
import Visible from '../../../../../../components/permissions/Visible'
import { administrativeStateColor } from '../../../../../../hooks/administrativeStateColor'
import { displayElementType } from '../../../../../../hooks/displayElementType'
import { DisplayElementIcon } from '../../../../../../hooks/displayIcons'
import { humanizeBandwidth } from '../../../../../../hooks/humanizeBandwidth'
import { ResourceType } from '../../../../../../slices/authorizationsApiSlice'
import {
  disableActionsForErrorStates,
  disableAdministrativeState,
} from '../../../../../../utils/states'

import AttachmentIcon from '../../../../../../assets/icons-io-v1/attachment.svg?react'
import TrashIcon from '../../../../../../assets/icons-io-v1/delete.svg?react'
import DetachmentIcon from '../../../../../../assets/icons-io-v1/detachment.svg?react'
import EditIcon from '../../../../../../assets/icons-io-v1/edit.svg?react'
import ShowIcon from '../../../../../../assets/icons-io-v1/show.svg?react'
import FormatMonthlyPrice from '../../../../../../components/format/monthlyPrice'
import useCurrentAccount from '../../../../../../hooks/useCurrentAccount'
import { useGetElementOperationalStatusMetricsQuery } from '../../../../../../slices/MetricsApiSlice'
import { ElementStates } from '../../../../../../slices/types'
import useFlowStore from '../Flow/hooks/useFlowStore'
import type { LogicalCloudNode } from '../types'
import LogicalCardRows from './CardRows'

const CloudNode: FC<NodeProps<LogicalCloudNode>> = ({
  width,
  height,
  id,
  targetPosition = Position.Top,
  sourcePosition = Position.Bottom,
  data,
  selected,
}) => {
  const { name, administrativeState, product, vlan } = data
  const { t } = useTranslation()
  const { currentAccount } = useCurrentAccount()
  const { workspaceId } = useParams()
  const { edges } = useFlowStore()

  const { data: operationalStatusData } =
    useGetElementOperationalStatusMetricsQuery(
      {
        accountId: currentAccount?.id,
        elementType: 'node',
        id: data?.id,
        workspaceId: workspaceId,
      },
      {
        refetchOnMountOrArgChange: true,
        skip: !data || data.administrativeState !== 'deployed',
      }
    )
  const navigate = useNavigate()

  const showDetails = useCallback(() => {
    navigate(`details/cloud/${id}/properties`)
  }, [id, navigate])
  const showDelete = useCallback(() => {
    navigate(`delete/cloud/${id}`)
  }, [id, navigate])
  const showEdit = useCallback(() => {
    navigate(`edit/cloud/${id}`)
  }, [id, navigate])

  const attachTarget = useCallback(() => {
    navigate(`attachment/cloud/${id}`)
  }, [id, navigate])

  const disableStateActions = disableAdministrativeState(administrativeState)

  const isAttached = useCallback(() => {
    return edges.some(
      (edge) => edge.type === 'attachment-edge' && edge.source === data.id
    )
  }, [data.id, edges])

  return (
    <>
      <NodeToolbar isVisible={selected}>
        <Card>
          <Toolbar>
            <Visible
              resource={ResourceType.Workspace}
              resourceId={workspaceId}
              action='get_node'>
              <div className='accordion__container-trash-icon'>
                <StyledIdCardIconButton
                  disabled={disableStateActions}
                  onClick={showDetails}>
                  <ShowIcon />
                </StyledIdCardIconButton>
              </div>
            </Visible>

            <Visible
              resource={ResourceType.Workspace}
              resourceId={workspaceId}
              action='edit_node'>
              <div className='accordion__container-trash-icon'>
                <StyledIdCardIconButton
                  disabled={
                    disableStateActions ||
                    disableActionsForErrorStates(administrativeState)
                  }
                  onClick={showEdit}>
                  <EditIcon />
                </StyledIdCardIconButton>
              </div>
            </Visible>
            <Visible
              resource={ResourceType.Workspace}
              resourceId={workspaceId}
              action='delete_node'>
              <div className='accordion__container-trash-icon'>
                <StyledIdCardIconButton
                  disabled={disableStateActions || isAttached()}
                  onClick={showDelete}>
                  <TrashIcon />
                </StyledIdCardIconButton>
              </div>
            </Visible>

            {!isAttached() ? (
              <Visible
                resource={ResourceType.Workspace}
                resourceId={data?.id}
                action='get_attachment'>
                <div className='accordion__container-trash-icon'>
                  <StyledIdCardIconButton onClick={attachTarget}>
                    {administrativeState === ElementStates.deployed && (
                      <AttachmentIcon />
                    )}
                  </StyledIdCardIconButton>
                </div>
              </Visible>
            ) : (
              <Visible
                resource={ResourceType.Workspace}
                resourceId={data?.id}
                action='delete_attachment'>
                <div className='accordion__container-trash-icon'>
                  <StyledIdCardIconButton onClick={attachTarget}>
                    {administrativeState === ElementStates.deployed && (
                      <DetachmentIcon />
                    )}
                  </StyledIdCardIconButton>
                </div>
              </Visible>
            )}
          </Toolbar>
        </Card>
      </NodeToolbar>
      <StyledLVCard
        variant='outlined'
        key={id}
        style={{
          height,
          width,
          borderColor: `${administrativeStateColor(administrativeState)}`,
        }}>
        <Handle
          onClick={attachTarget}
          position={targetPosition}
          type='target'
        />
        <Handle
          style={{
            pointerEvents: 'none',
          }}
          position={sourcePosition}
          type='source'
        />
        <CardHeader
          titleTypographyProps={{
            noWrap: true,
            textOverflow: 'ellipsis',
          }}
          avatar={
            <Avatar variant='square'>
              <DisplayElementIcon
                kind='node'
                type='cloud'
                csp={product.cspName}
              />
            </Avatar>
          }
          title={name}
          subheader={displayElementType('node', 'cloud', t)}
        />
        <CardContent>
          {<FormatMonthlyPrice price={product.priceMrc} />}
          <LogicalCardRows
            title={t('element.CSP_REGION')}
            value={product.cspRegion}
          />
          <LogicalCardRows
            title={t('element.BANDWIDTH')}
            value={humanizeBandwidth(product.bandwidth)}
          />
          <LogicalCardRows
            title={t('element.MRC')}
            value={<FormatMonthlyPrice price={product.priceMrc} />}
          />
          <LogicalCardRows
            title={t('element.NODE_OP_STATUS')}
            value={
              <OperationalStatus
                status={operationalStatusData?.status}
                showStatusText={true}
              />
            }
          />
        </CardContent>
        <CardContent>
          <LogicalCardRows
            title={t('element.CSP_NAME')}
            value={product.cspName}
          />
          <LogicalCardRows
            title={t('element.CSP_CITY')}
            value={product.cspCity}
          />
          <LogicalCardRows
            title={t('element.LOCATION')}
            value={product.cspCity}
          />
          <LogicalCardRows
            title={t('element.PROVIDER')}
            value={product.provider}
          />
          <LogicalCardRows title={t('element.VLAN')} value={vlan} />
        </CardContent>
        {/* <Handle position={sourcePosition} type='source' /> // Available when provider CSP will be a node */}
      </StyledLVCard>
    </>
  )
}

export default CloudNode
