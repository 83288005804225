import type { LayoutOptions } from 'elkjs'

export type LayoutDirectionType = 'RIGHT' | 'DOWN' // | 'LEFT'  | 'UP'

export enum LayoutId {
  DiscoStress = 'DiscoStress',
  Stress = 'Stress',
  DiscoLayered = 'DiscoLayered',
  Force = 'Force',
  DiscoForce = 'DiscoForce',
  Layered = 'Layered',
}

export type OrderingStrategy =
  | 'NONE'
  | 'NODES_AND_EDGES'
  | 'PREFER_EDGES'
  | 'PREFER_NODES'
export type LayeredNodePlacementStrategy =
  | 'SIMPLE'
  | 'INTERACTIVE'
  | 'LINEAR_SEGMENTS'
  | 'BRANDES_KOEPF'
  | 'NETWORK_SIMPLEX'

type LayeredOptions = {
  'org.eclipse.elk.layered.nodePlacement.strategy'?: LayeredNodePlacementStrategy
  'org.eclipse.elk.layered.spacing.nodeNodeBetweenLayers'?: string
  'org.eclipse.elk.layered.considerModelOrder.strategy'?: OrderingStrategy
}

type StressOptions = {
  'org.eclipse.elk.stress.desiredEdgeLength'?: string
}
type ForceOptions = {}

export type ELKPortSideValue = 'NORTH' | 'SOUTH' | 'EAST' | 'WEST'

export type LayoutItem = {
  id: LayoutId
  label: string
  layoutOptions: LayoutOptions & {
    'org.eclipse.elk.direction'?: LayoutDirectionType
    'org.eclipse.elk.debugMode'?: 'true' | 'false'
    'org.eclipse.elk.spacing.nodeNode'?: string
  } & (
      | ({
          'org.eclipse.elk.algorithm': 'disco'
          'org.eclipse.elk.disco.componentCompaction.componentLayoutAlgorithm': 'layered'
        } & LayeredOptions)
      | ({
          'org.eclipse.elk.algorithm': 'disco'
          'org.eclipse.elk.disco.componentCompaction.componentLayoutAlgorithm': 'stress'
        } & StressOptions)
      | ({
          'org.eclipse.elk.algorithm': 'disco'
          'org.eclipse.elk.disco.componentCompaction.componentLayoutAlgorithm': 'force'
        } & ForceOptions)
      | ({
          'org.eclipse.elk.algorithm': 'stress'
        } & StressOptions)
      | ({
          'org.eclipse.elk.algorithm': 'force'
        } & ForceOptions)
      | ({
          'org.eclipse.elk.algorithm': 'layered'
        } & LayeredOptions)
    )
}
