import { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import type { ActionCreatorsMapObject } from 'redux'
import { bindActionCreators } from 'redux'

export const useActions = <A, M extends ActionCreatorsMapObject<A>>(
  actions: M
): M => {
  const dispatch = useDispatch()
  const [state] = useState(actions)
  return useMemo(() => bindActionCreators(state, dispatch), [state, dispatch])
}
