// -- NPM IMPORTS
import { MenuItem } from '@mui/material'
import { useCallback, useRef } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

// -- COMPONENT IMPORTS
import useCurrentAccount from '../../../hooks/useCurrentAccount'
import type { Profile } from '../../../slices/types'
import { useGetFiltersUsersQuery } from '../../../slices/usersApiSlice'
import { useCreateUserProfileInWorkspaceV2Mutation } from '../../../slices/workspacesApiSliceV2'

// -- ASSETS IMPORTS
// import "./style.scss"
import useResizeObserver from 'use-resize-observer'
import CloseIcon from '../../../assets/icons-24/close.svg?react'
import {
  StyledActionButton,
  StyledButtonClose,
  StyledInputLabel,
  StyledTextField,
} from '../../../components/forms/StyledFormComponents'
import { useSnackbar } from '../../../hooks/useSnackbar'

export type AddUserProfileProps = {
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>
  profiles: Profile[]
  workspaceId: string
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

interface inputForm {
  userId: string
  profileId: string
}

export default function AddUserProfile({
  setOpenDrawer,
  profiles,
  workspaceId,
  setLoading,
}: AddUserProfileProps) {
  const { showError, showSuccess } = useSnackbar()
  const { currentAccount } = useCurrentAccount()
  const { t } = useTranslation()
  const { register, handleSubmit } = useForm<inputForm>({
    shouldUseNativeValidation: true,
  })

  const { data: users } = useGetFiltersUsersQuery(
    { accountId: currentAccount?.id, query: { admin: false } },
    {
      skip: !currentAccount?.id,
      refetchOnMountOrArgChange: true,
    }
  )

  const [createUserProfile] = useCreateUserProfileInWorkspaceV2Mutation()

  const onSubmit: SubmitHandler<inputForm> = useCallback(
    async (data) => {
      const { ...newData } = data
      setLoading(true)
      try {
        await createUserProfile({
          accountId: currentAccount?.id,
          workspaceId: workspaceId,
          userProfile: newData,
        }).unwrap()
        setLoading(false)
        setOpenDrawer(false)
        showSuccess(t('user.ADDED_WS_USER'))
      } catch (e) {
        setLoading(false)
        showError(e)
      }
    },
    [
      setLoading,
      createUserProfile,
      currentAccount?.id,
      workspaceId,
      setOpenDrawer,
      t,
      showError,
      showSuccess,
    ]
  )

  const closeDrawer = useCallback(() => {
    setOpenDrawer(false)
  }, [setOpenDrawer])

  // ----- Action Buttons Fixed Position Fix ----- //
  const drawerWidth = useRef(null)
  const { width } = useResizeObserver({ ref: drawerWidth })
  //     - please add the following reference to the parent container
  //     - that will define the defined width
  //  TODO ref={drawerWidth}
  // ----- END Action Buttons Fixed Position Fix ----- //

  return (
    <section className='drawer-create__wrapper' ref={drawerWidth}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='user__form--close'>
          <StyledButtonClose onClick={() => closeDrawer()}>
            <CloseIcon />
          </StyledButtonClose>
        </div>
        <div className='user__form__title'>{t('user.ADD_A_USER')}</div>
        <div className='user__form__container--field'>
          <StyledInputLabel shrink htmlFor='user-form-name'>
            {t('user.NAME')}
          </StyledInputLabel>
          <StyledTextField
            select
            required
            id='user-form-name'
            size='small'
            data-test='input-profile'
            SelectProps={{
              defaultValue: '',
            }}
            {...register('userId', {
              required: t('user.NAME_REQUIRED'),
            })}>
            {users ? (
              users?.map((user) => {
                return (
                  <MenuItem key={user.id} value={user.id}>
                    {user?.name}
                  </MenuItem>
                )
              })
            ) : (
              <div />
            )}
          </StyledTextField>
        </div>
        <div className='user__form__container--field'>
          <StyledInputLabel shrink htmlFor='user-form-profile'>
            {t('user.PROFILE')}
          </StyledInputLabel>
          <StyledTextField
            select
            required
            id='user-form-profile'
            size='small'
            data-test='input-profile'
            SelectProps={{
              defaultValue: '',
              MenuProps: {
                PaperProps: {
                  sx: {
                    color: 'var(--content-primary)',
                    border: '1px solid var(--border-primary)',
                    background: 'var(--background-primary)',
                    borderRadius: '1px',
                  },
                },
              },
            }}
            {...register('profileId', {
              required: t('user.PROFILE_REQUIRED'),
            })}>
            {profiles ? (
              profiles?.map((profile) => {
                return (
                  <MenuItem key={profile.id} value={profile.id}>
                    {t(`user.${profile?.name?.toUpperCase()}`)}
                  </MenuItem>
                )
              })
            ) : (
              <div />
            )}
          </StyledTextField>
        </div>

        <div className='element__form--btn' style={{ width }}>
          <StyledActionButton
            sx={{
              width: '49.5%',
              backgroundColor: 'var(--background-tertiary)',
              '&:hover': {
                backgroundColor: 'var(--background-secondary)',
              },
            }}
            color='primary'
            variant='text'
            // startIcon={<TrashIcon />}
            onClick={() => closeDrawer()}>
            {t('element.DISCARD')}
          </StyledActionButton>
          <StyledActionButton
            sx={{ width: '49.5%', marginLeft: '1%' }}
            type='submit'
            color='primary'
            variant='contained'>
            {t('user.ADD')}
          </StyledActionButton>
        </div>
      </form>
    </section>
  )
}
