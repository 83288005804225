import { useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useSelfQuery } from '../../slices/authApiSlice'
import { selectCurrentToken } from '../../slices/authSlice'
import Logout from '../Login/logout'

const RequireAuth = () => {
  const token = useSelector(selectCurrentToken)
  const { isLoading, isUninitialized, isError } = useSelfQuery(null, {
    skip: !token,
  })
  const location = useLocation()

  if (!token) {
    return <Navigate to='/login' state={{ from: location }} />
  }
  if (isError) {
    return <Logout />
  }

  if (isLoading || isUninitialized) {
    return null
  }

  return <Outlet />
}

export default RequireAuth
