// -- NPM Imports
import type { FC } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

// -- Import Components

// -- Import Assets
import styled from '@emotion/styled'
import { Button } from '@mui/material'
import GlobalMap from '../../components/map/GlobalMap'
import './style.scss'

type DashboardProps = {}

type Active = 'World' | 'Europe'

const StyleButton = styled(Button)(() => ({
  '&.Mui-disabled': {
    border: '1px solid var(--border-primary)',
    color: 'var(--content-secondary)',
  },
}))

const Dashboard: FC<DashboardProps> = () => {
  const { t } = useTranslation()

  const [activeState, setActiveState] = useState<Active>('World')

  const setActiveStateWorld = () => setActiveState('World')
  const setActiveStateEurope = () => setActiveState('Europe')

  return (
    <div className='dashboard__container'>
      {t('model.DASHBOARD')}
      <div className='dashboard__table-header-action'>
        <StyleButton
          variant='outlined'
          className={activeState === 'World' ? 'active' : 'inactive'}
          onClick={setActiveStateWorld}>
          {t('common.WORLD')}
        </StyleButton>
        <StyleButton
          variant='outlined'
          className={activeState === 'Europe' ? 'active' : 'inactive'}
          onClick={setActiveStateEurope}>
          {t('common.EUROPE')}
        </StyleButton>
      </div>
      <div>
        <GlobalMap activeState={activeState} />
      </div>
    </div>
  )
}

export default Dashboard
