import { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { useActions } from '../../hooks/useActions'
import { logoutAction } from '../../slices/authActions'
import { useRevokeMutation } from '../../slices/authApiSlice'

export default function Logout() {
  const { logout } = useActions({ logout: logoutAction })
  const [revoke] = useRevokeMutation()

  useEffect(() => {
    revoke()
    logout()
  }, [logout, revoke])

  return <Navigate to='/' />
}
