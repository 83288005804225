// -- Import NPM
import { useMemo, type FC } from 'react'
import { useTranslation } from 'react-i18next'

// -- Import Components

// -- Import Assets
import styled from '@emotion/styled'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import {
  NavLink,
  Outlet,
  resolvePath,
  useMatch,
  useResolvedPath,
} from 'react-router-dom'
import './styles.scss'

const StyledTab = styled(Tab)<{ to: string }>({
  '&.MuiTab-root': {
    color: 'var(--content-primary)',
  },
  '&.Mui-selected': {
    color: 'var(--content-active)',
  },
  '&.MuiTabs-indicator': {
    bottom: '30px',
  },
})

type SettingsProps = {}

const Settings: FC<SettingsProps> = () => {
  const { t } = useTranslation()
  const generalPath = useResolvedPath('general')
  const securityPath = useResolvedPath('security')
  const tokensPath = useResolvedPath('tokens')

  const matchesSettings = useMatch('/settings/:tabValue/*')

  const routes: { path: string; labelKey: string }[] = useMemo(
    () => [
      { path: generalPath.pathname, labelKey: 'general' },
      {
        path: securityPath.pathname,
        labelKey: 'security',
      },
      { path: tokensPath.pathname, labelKey: 'tokens' },
    ],
    [generalPath, securityPath, tokensPath]
  )

  if (!matchesSettings) return <Outlet />
  return (
    <main className='settings__wrapper'>
      <div className='settings__header-container'>
        <div className='settings__header-title'>{t('page.SETTINGS')}</div>
        <Tabs
          TabIndicatorProps={{
            sx: {
              bottom: '15px',
            },
          }}
          value={matchesSettings.pathnameBase}
          variant='fullWidth'
          sx={{
            height: '100%',
            '& .MuiTabs-scroller': {
              padding: '0 30px',
              display: 'flex',
              alignItems: 'center',
            },
          }}>
          {routes.map(({ labelKey, path }) => {
            return (
              <StyledTab
                key={path}
                LinkComponent={NavLink}
                to={path}
                label={t(`settings.tabs.${labelKey.toUpperCase()}`)}
                value={resolvePath(path).pathname}
              />
            )
          })}
        </Tabs>
      </div>
      <Outlet />
    </main>
  )
}

export default Settings
