import styled from '@emotion/styled'
import { Avatar, CardContent, CardHeader, Typography } from '@mui/material'
import type { NodeProps } from '@xyflow/react'
import { Handle, Position } from '@xyflow/react'
import type { FC } from 'react'
import { StyledLVCard } from '../../../../../../components/cards/styledLogicalViewCard'
import { displayCspWithLogo } from '../../../../../../hooks/displayIcons'
import type { ServiceProvider } from '../../../../../../slices/types'

import { t } from 'i18next'
import Equinix from '../../../../../../assets/csp/equinix.png'
import InterCloud from '../../../../../../assets/csp/intercloud-white.svg?url'
import Megaport from '../../../../../../assets/csp/megaport.png'
import LogicalCardRows from './CardRows'

const AvatarLabel = styled.div`
  display: flex;
  align-items: center;
`

const avatarURL = (provider: string) => {
  switch (provider.toLowerCase()) {
    case 'megaport':
      return Megaport
    case 'equinix':
      return Equinix
    case 'intercloud':
      return InterCloud
    default:
      return ''
  }
}

const ServiceProviderNode: FC<
  Omit<NodeProps, 'data'> & { data: ServiceProvider }
> = ({ id, width, height, targetPosition = Position.Top, data }) => {
  const { name, provider, cspName } = data

  return (
    <>
      <StyledLVCard
        variant='outlined'
        key={id}
        style={{
          width,
          height,
          //borderColor: `${administrativeStateColor(administrativeState)}`,
        }}>
        <Handle position={targetPosition} type='target' isConnectable={false} />
        <CardHeader
          titleTypographyProps={{
            noWrap: true,
            textOverflow: 'ellipsis',
          }}
          avatar={
            <Avatar variant='square'>
              {displayCspWithLogo(cspName, '32px', '32px', false)}
            </Avatar>
          }
          title={name}
          subheader={'Service provider'}
        />
        <CardContent>
          <LogicalCardRows
            title={t('element.PROVIDER')}
            value={
              <AvatarLabel>
                <Avatar
                  style={{ marginRight: '14px', stroke: 'black' }}
                  alt={provider}
                  src={avatarURL(provider)}
                />
                <Typography>{provider}</Typography>
              </AvatarLabel>
            }
          />
        </CardContent>
      </StyledLVCard>
    </>
  )
}

export default ServiceProviderNode
