// -- Import NPM
import { Backdrop, CircularProgress } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

// -- Import Components
import useCurrentAccount from '../../hooks/useCurrentAccount'
import { ResourceType } from '../../slices/authorizationsApiSlice'
import {
  useDeletePhysicalPortMutation,
  useGetPortsbyFiltersQuery,
} from '../../slices/portsApiSlice'
import { PortStates } from '../../slices/types'

// -- Import Assets
import { Route, Routes } from 'react-router-dom'
import AccordionElementsV2 from '../../components/accordions/AccordionElementsV2'
import DrawerCreateItem from '../../components/drawer/DrawerCreateItem'
import DrawerDeleteItem from '../../components/drawer/DrawerDeleteItem'
import DrawerReadOnly from '../../components/drawer/DrawerReadOnly'
import SelectFilterMenu from '../../components/fields/SelectFilterMenu'
import PageHeaderV2 from '../../components/header/PageHeaderV2'
import { useSnackbar } from '../../hooks/useSnackbar'
import './style.scss'

const portsFilters = [
  {
    id: 0,
    filter: 'All',
  },
  {
    id: 1,
    filter: PortStates.created,
  },
  {
    id: 2,
    filter: PortStates.deleted,
  },
]

export default function PortsList() {
  const { showError, showSuccess } = useSnackbar()
  const { t } = useTranslation()
  const { currentAccount, error } = useCurrentAccount()

  const [filter, setFilter] = useState<string>()
  const {
    data: filteredData,
    isSuccess: portsSuccess,
    error: errorPortsData,
  } = useGetPortsbyFiltersQuery(
    { accountId: currentAccount?.id, query: { state: filter } },
    {
      skip: !currentAccount?.id,
    }
  )

  // DRAWERS STATES
  const [deletePhysicalPort] = useDeletePhysicalPortMutation()
  const [loading, setLoading] = useState(true)

  // This useEffect checks that the Data[] is loaded and sets the backdrop Loader to false
  useEffect(() => {
    if (portsSuccess) setLoading(false)
  }, [portsSuccess])
  // ----- SNACKBAR CONTROL ----- //
  // ---------------------------- //

  useEffect(() => {
    const err = error || errorPortsData
    if (!err) return
    showError(
      error || errorPortsData,
      t('messages.errors.ERR_GET_PHYSICAL_PORT_VALIDATE')
    )
  }, [showError, error, errorPortsData, t])

  const handleClose = useCallback(
    async (newValue?: boolean, element?: { id: string }) => {
      setLoading(true)
      if (newValue) {
        try {
          await deletePhysicalPort({
            accountId: currentAccount?.id,
            id: element?.id,
          }).unwrap()
          setLoading(false)
          showSuccess(t('ports.messages.PORT_DELETED_WITH_SUCCESS'))
        } catch (e) {
          setLoading(false)
          showError(e)
        }
      }
    },
    [currentAccount?.id, deletePhysicalPort, t, showSuccess, showError]
  )

  return (
    <div className='ports__container'>
      <Backdrop
        sx={{ color: 'var(--root-color-action)', zIndex: '9999' }}
        open={loading}>
        <CircularProgress color='inherit' />
      </Backdrop>

      {!error && (
        <>
          <PageHeaderV2
            page={t('PORTS')}
            addItemFor={t('ports.ORDER_A_PORT')}
            permCreate={[
              ResourceType.Account,
              currentAccount?.id,
              'create_port',
            ]}
          />

          <SelectFilterMenu filters={portsFilters} setFilter={setFilter} />

          {filteredData?.data?.length <= 0 ? (
            <div className='ports__container--first-message'>
              {t(`ports.messages.EMPTY_LIST_MESSAGE`)}
            </div>
          ) : (
            <div className='ports__container--accordions'>
              {filteredData?.data?.map((element) => {
                return (
                  <AccordionElementsV2 key={element?.id} element={element} />
                )
              })}
            </div>
          )}
        </>
      )}

      <Routes>
        <Route
          path='/create/*'
          element={<DrawerCreateItem setLoading={setLoading} />}
        />
        <Route
          path='/details/:type/:elementId/:detailsTabView' // @FIXME '/details/port/:elementId' only
          element={<DrawerReadOnly />}
        />
        <Route
          path='/delete/port/:elementId'
          element={<DrawerDeleteItem handleClose={handleClose} />}
        />
      </Routes>
    </div>
  )
}
