// -- NPM IMPORTS
import {
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  CircularProgress,
  IconButton,
  Tooltip,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import {
  useCallback,
  useEffect,
  useState,
  type ChangeEventHandler,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

// -- COMPONENT IMPORTS
import PageHeader from '../../components/header/PageHeader'
import Visible from '../../components/permissions/Visible'
import useCurrentAccount from '../../hooks/useCurrentAccount'
import { useSelfQuery } from '../../slices/authApiSlice'
import { selectCurrentToken } from '../../slices/authSlice'
import { ResourceType } from '../../slices/authorizationsApiSlice'
import type { Account, User } from '../../slices/types'
import { UserProfileType } from '../../slices/types'
import {
  useGetUsersQuery,
  useUpdateUserMutation,
} from '../../slices/usersApiSlice'
import UserAvatar from './Avatar'

// -- ASSETS IMPORTS
import InfoIcon from '@mui/icons-material/Info'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import TrashIcon from '../../assets/icons-16/trash.svg?react'
import {
  StyledCheckBox,
  StyledFormControlLabel,
} from '../../components/fields/StyledCheckBox'
import {
  StyledAccordion,
  StyledButton,
} from '../../components/forms/StyledFormComponents'
import { useSnackbar } from '../../hooks/useSnackbar'
import { useSendResetPasswordMutation } from '../../slices/setPassWordAPISlice'
import './style.scss'

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '&.MuiIconButton-root': {
    color: 'var(--content-primary)',
  },
  '&.Mui-disabled': {
    color: 'var(--content-inactive)',
    opacity: 0.5,
  },
  '&.Mui-disabled:hover': { background: theme.palette.secondary.main },
}))

interface UsersListRowProps {
  me: User
  user: User
  currentAccount: Account
}

const UsersListRow = ({ me, user, currentAccount }: UsersListRowProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [updateUser] = useUpdateUserMutation()
  const [sendResetPassword] = useSendResetPasswordMutation()
  const { showError, showSuccess } = useSnackbar()

  const { id, name, email, isAdmin, lastConnection } = user

  const onClickEdit = useCallback(() => {
    navigate(`edit/${id}`)
  }, [navigate, id])
  const onClickDelete = useCallback(() => {
    navigate(`delete/${id}`)
  }, [navigate, id])
  const onClickRevokeTokens = useCallback(() => {
    navigate(`revoke-tokens/${id}`)
  }, [navigate, id])

  const handleSendResetPassword = useCallback(async () => {
    try {
      await sendResetPassword({ email }).unwrap()
      showSuccess(t('messages.RESET_EMAIL_SENT'))
    } catch (e) {
      showError(e)
    }
  }, [email, sendResetPassword, showError, showSuccess, t])

  const updateUserOnClick: ChangeEventHandler<HTMLInputElement> = useCallback(
    async ({ target: { checked } }) => {
      const { id, name } = user
      try {
        await updateUser({
          accountId: currentAccount.id,
          user: {
            id,
            name,
            profileId: checked
              ? UserProfileType.AccountAdmin
              : UserProfileType.AccountView,
          },
        }).unwrap()
        showSuccess(t('user.UPDATED_USER'))
      } catch (e) {
        showError(e)
      }
    },
    [currentAccount.id, showError, showSuccess, t, updateUser, user]
  )

  return (
    <StyledAccordion
      key={id}
      disableGutters={true}
      className='accordion__container-accordion'>
      <div className='accordion__container-summary-wrapper'>
        <AccordionSummary className='accordion__container-summary workspace__card-container'>
          <div className=''>
            <UserAvatar userName={name} />
          </div>
          <div className='accordion__container-space-between'>
            <div className=' user__accordion--column'>
              <span>{name}</span>
              <span className='workspace__card-last-edited'>{email}</span>
            </div>

            <div className='accordion__element-profile user__accordion--column'>
              <span className='workspace__card-last-edited'>
                {t('user.PROFILE')}
              </span>
              <span>
                {isAdmin ? 'Account administrator' : 'Account viewer'}
              </span>
            </div>

            <div className='accordion__element-profile user__accordion--column'>
              <span className='workspace__card-last-edited'>
                {t('user.LAST_CONNECTION')}
              </span>
              <span>
                {lastConnection
                  ? moment(lastConnection).format('MMM DD, HH:mm')
                  : '-'}
              </span>
            </div>
          </div>
        </AccordionSummary>

        <div className='accordion__element-state--trash-icon'>
          <StyledButton onClick={onClickEdit} variant='outlined'>
            {t('user.EDIT')}
          </StyledButton>
        </div>

        <div className='user__accordion--trash-icon'>
          <Visible
            resource={ResourceType.Account}
            resourceId={currentAccount?.id}
            action={'delete_account_user'}>
            <StyledIconButton onClick={onClickDelete} disabled={me?.id === id}>
              <TrashIcon />
            </StyledIconButton>
          </Visible>
        </div>
      </div>

      <AccordionDetails>
        <div className='accordion__container-open'>
          <StyledButton
            onClick={handleSendResetPassword}
            variant='outlined'
            sx={{ marginRight: '24px' }}
            endIcon={
              <Tooltip title={t('user.messages.SEND_RESET_PASSWORD_PROCEDURE')}>
                <InfoIcon />
              </Tooltip>
            }>
            {t('user.SET_PASSWORD')}
          </StyledButton>
          <StyledButton
            onClick={onClickRevokeTokens}
            variant='outlined'
            sx={{ marginRight: '24px' }}>
            {t('user.REVOKE_TOKENS')}
          </StyledButton>
          <StyledFormControlLabel
            control={
              <StyledCheckBox
                value={'account_admin'}
                onChange={updateUserOnClick}
                disabled={me?.id === id}
                checked={isAdmin}
              />
            }
            label='is Admin'
            disableTypography={true}
          />
        </div>
      </AccordionDetails>
    </StyledAccordion>
  )
}

export default function UsersList() {
  const { showError } = useSnackbar()
  const { t } = useTranslation()
  const { currentAccount, error: currentAccountError } = useCurrentAccount()

  // -------------------------------------------------------- //
  // ----- Get Self Data, and fix refetchOnMount issue ----- //
  const token = useSelector(selectCurrentToken)
  const { data: MeData } = useSelfQuery(null, {
    skip: !token,
  })

  const {
    data: users,
    isSuccess: userSuccess,
    error: getUsersError,
  } = useGetUsersQuery(
    currentAccount?.id, // the new route has a param and needs to be set to true to see all the users
    {
      skip: !currentAccount?.id,
      refetchOnMountOrArgChange: true,
    }
  )

  const error = currentAccountError || getUsersError

  useEffect(() => {
    if (!error) return
    setLoading(false)
    showError(error)
  }, [error, showError])

  // ----- END Get Self Data, and fix refetchOnMount issue ----- //
  // ----------------------------------------------------------- //

  // ---------------------------- //
  // ----- SNACKBAR CONTROL ----- //
  const [loading, setLoading] = useState(true)

  // This useEffect checks that the Data[] is loaded and sets the backdrop Loader to false
  useEffect(() => {
    if (userSuccess) setLoading(false)
  }, [userSuccess])

  if (!currentAccount) return
  return (
    <>
      <Backdrop
        sx={{ color: 'var(--root-color-action)', zIndex: '9999' }}
        open={loading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      {!error && (
        <div className='workspace__container'>
          <PageHeader
            page='USERS'
            addItemFor={t('user.ADD_A_USER')}
            permCreate={[
              ResourceType.Account,
              currentAccount?.id,
              'add_account_user',
            ]}
          />

          {users?.map((user: User) => {
            return (
              <UsersListRow
                key={user.id}
                me={MeData}
                user={user}
                currentAccount={currentAccount}
              />
            )
          })}
        </div>
      )}
    </>
  )
}
