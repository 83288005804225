import { Box, Typography } from '@mui/material'
import { useCallback, type FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { MainDrawer } from '../../components/drawer/MainDrawer'
import { StyledActionButton } from '../../components/forms/StyledFormComponents'
import useCurrentAccount from '../../hooks/useCurrentAccount'
import { useSnackbar } from '../../hooks/useSnackbar'
import {
  useDeleteUserMutation,
  useGetUserByIdQuery,
} from '../../slices/usersApiSlice'

interface AddUserProps {
  onClose: () => void
}

const DeleteUser: FC<AddUserProps> = ({ onClose }) => {
  const { id } = useParams()
  const { t } = useTranslation()

  const { currentAccount } = useCurrentAccount()
  const [deleteUser, { isLoading }] = useDeleteUserMutation()
  const { data: userData } = useGetUserByIdQuery(
    { accountId: currentAccount?.id, id },
    {
      skip: !currentAccount?.id || !id,
    }
  )

  const { showError, showSuccess } = useSnackbar()

  const onDeleteUser = useCallback(async () => {
    try {
      await deleteUser({
        accountId: currentAccount?.id,
        id,
      }).unwrap()
      showSuccess(t('user.DELETED_USER'))
    } catch (e) {
      showError(e)
    } finally {
      onClose()
    }
  }, [deleteUser, currentAccount?.id, id, showSuccess, t, showError, onClose])

  if (!userData) {
    return null
  }

  return (
    <MainDrawer
      onClose={onClose}
      body={
        <Box flexGrow={1} className='wrapper__content'>
          <Typography
            variant='h5'
            gutterBottom
            style={{
              fontWeight: 600,
              fontSize: '18px',
              lineHeight: '21px',
              color: 'var(--content-primary)',
              marginBottom: '16px',
            }}>
            {t('modal.CONFIRM_DELETION')}
          </Typography>
          <Box display='flex' flexDirection='column' gap='16px'>
            {t('modal.CONFIRM_USER_DELETION', {
              username: userData?.name,
            })}
          </Box>
        </Box>
      }
      footer={
        <Box display='flex' gap='1%' flexGrow={1}>
          <StyledActionButton
            sx={{
              backgroundColor: 'var(--background-tertiary)',
              '&:hover': {
                backgroundColor: 'var(--background-secondary)',
              },
            }}
            color='primary'
            variant='text'
            onClick={onClose}>
            {t('element.DISCARD')}
          </StyledActionButton>
          <StyledActionButton
            type='submit'
            color='primary'
            variant='contained'
            disabled={isLoading}
            onClick={onDeleteUser}>
            {t('modal.CONFIRM')}
          </StyledActionButton>
        </Box>
      }
    />
  )
}

export default DeleteUser
