import { ButtonGroup } from '@mui/material'
import classNames from 'classnames'
import type { FC } from 'react'
import { Outlet } from 'react-router-dom'
import AccordionElementsV2 from '../../../../../components/accordions/AccordionElementsV2'
import type {
  AccessElement,
  CloudElement,
  TransportElementV2,
  WorkspaceV2,
} from '../../../../../slices/types'
import MapViewSwitcher from '../../../Components/MapViewSwitcher'
import NoElementYet from '../../NoElementYet'

export interface ListProps {
  elements: (
    | CloudElement
    | AccessElement
    | Omit<TransportElementV2, 'attachments'>
  )[] // @FIXME not ConcatElementV2
  workspaceData: WorkspaceV2
}
const List: FC<ListProps> = ({ elements = [], workspaceData }) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          position: 'relative',
          justifyContent: 'flex-end',
        }}
        className={classNames('react-flow__panel', 'top', 'right')}>
        <ButtonGroup
          style={{
            gap: '0.5rem',
          }}>
          <MapViewSwitcher />
        </ButtonGroup>
      </div>
      <div className='workspace__design--container'>
        {!elements.length && (
          <NoElementYet
            noElementMessage={'There are no elements in this workspace.'}
          />
        )}

        {elements.map((element) => {
          // @FIXME not ConcatElementV2
          return (
            <AccordionElementsV2
              key={element.id}
              element={element}
              workspaceData={workspaceData}
            />
          )
        })}
      </div>
      <Outlet />
    </>
  )
}

export default List
