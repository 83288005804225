import type { Edge, Node } from '@xyflow/react'
import type {
  AccessElementDataV2,
  AccessElementV2,
  AttachmentElementData,
  CloudElementDataV2,
  ElementStates,
  ServiceProvider,
  TransportElementV2,
} from '../../../../../slices/types'

type WorkspaceId = {
  workspaceId: string
}

export enum LogicalNodeType {
  Cloud = 'cloud',
  Access = 'access',
  Transport = 'transport',
  PhysicalPort = 'physical-port',
  ServiceProvider = 'service-provider',
}

export enum LogicalEdgeType {
  Attachment = 'attachment-edge',
  Physical = 'physical-edge',
  ServiceProvider = 'service-provider-edge',
}

export type PhysicalPortLinkElement = {}

export type LogicalCloudNode = Node<
  CloudElementDataV2 & WorkspaceId,
  LogicalNodeType.Cloud
>
export type LogicalAccessNode = Node<
  AccessElementDataV2 & WorkspaceId,
  LogicalNodeType.Access
>
export type LogicalTransportNode = Node<
  TransportElementV2 & WorkspaceId,
  LogicalNodeType.Transport
>
export type LogicalPhysicalPortNode = Node<
  AccessElementV2['data']['physicalPort'] & WorkspaceId,
  LogicalNodeType.PhysicalPort
>

export type LogicalServiceProviderNode = Node<
  ServiceProvider,
  LogicalNodeType.ServiceProvider
>

export type LogicalNodes =
  | LogicalCloudNode
  | LogicalAccessNode
  | LogicalTransportNode
  | LogicalPhysicalPortNode
  | LogicalServiceProviderNode

export type LogicalAttachmentEdge = Edge<
  AttachmentElementData,
  LogicalEdgeType.Attachment
>
export type LogicalPhysicalEdge = Edge<
  PhysicalPortLinkElement,
  LogicalEdgeType.Physical
>

export type ServiceProviderLink = {
  administrativeState: ElementStates
}
export type LogicalServiceProviderEdge = Edge<
  ServiceProviderLink,
  LogicalEdgeType.ServiceProvider
>

export type LogicalEdges =
  | LogicalAttachmentEdge
  | LogicalPhysicalEdge
  | LogicalServiceProviderEdge
