import type { SelectProps } from '@mui/material'
import type { FC, PropsWithChildren } from 'react'
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

import type { LayoutDirectionType, LayoutItem } from './types'
import { LayoutId } from './types'
import { defaultDirection, layouts } from './utils'

const defaultMockLayout = layouts.find(({ id }) => id === LayoutId.Layered)

const LayoutFormContext = createContext<LayoutForm | null>(null)

type LayoutForm = {
  selectedLayout: LayoutItem
  selectedDirection: LayoutDirectionType
  handleChangeLayout: SelectProps<string>['onChange']
  handleChangeDirection: SelectProps<LayoutDirectionType>['onChange']
  canSelectDirection: boolean
}

export const LayoutFormProvider: FC<PropsWithChildren> = ({ children }) => {
  const [selectedLayout, setSelectedLayout] = useState(defaultMockLayout)
  const [selectedDirection, setselectedDirection] =
    useState<LayoutDirectionType>(defaultDirection)

  const handleChangeLayout: LayoutForm['handleChangeLayout'] = useCallback(
    (evt) => {
      setSelectedLayout(
        layouts.find(({ id }) => {
          return id === evt.target.value
        })
      )
    },
    []
  )

  const handleChangeDirection: LayoutForm['handleChangeDirection'] =
    useCallback((evt) => {
      setselectedDirection(evt.target.value === 'DOWN' ? 'DOWN' : 'RIGHT')
    }, [])

  const canSelectDirection = useMemo(() => {
    const { layoutOptions } = layouts.find(({ id }) => {
      return selectedLayout.id === id
    })

    return 'org.eclipse.elk.direction' in layoutOptions
  }, [selectedLayout.id])

  const value = useMemo(
    () => ({
      defaultMockLayout,

      selectedLayout,
      selectedDirection,

      handleChangeLayout,
      handleChangeDirection,

      canSelectDirection,
    }),
    [
      canSelectDirection,
      handleChangeDirection,
      handleChangeLayout,
      selectedDirection,
      selectedLayout,
    ]
  )

  return (
    <LayoutFormContext.Provider value={value}>
      {children}
    </LayoutFormContext.Provider>
  )
}

const useLayoutForm = (): LayoutForm => {
  return useContext(LayoutFormContext)
}

export default useLayoutForm
