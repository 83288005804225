// -- NPM IMPORTS
import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
  Button,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useCallback, useState } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import FooterLogo from '../../assets/logos/footer-logo.svg?react'
import { SIGNUP_URL } from '../../environments/env'

// -- COMPONENT IMPORTS
import { useLoginMutation } from '../../slices/authApiSlice'

// -- ASSETS IMPORTS
import { useSnackbar } from '../../hooks/useSnackbar'
import './style.scss'
import TopLogo from './TopLogo'

export default function Login() {
  const { showError } = useSnackbar()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [login] = useLoginMutation()

  const { register, handleSubmit, reset } = useForm({
    shouldUseNativeValidation: true,
  })

  const [otpEnabled, setOtpEnabled] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState(false)
  // Retreive the stae for the snackbar comming in from another vue
  const location = useLocation()
  const { state } = location
  window.history.replaceState({}, '')

  /*
   * HANDLERS :
   * login form on submit handler : handleLoginForm
   * email verification handler : handleEmailFormat
   * password verification hadnler : handlePasswordFormat
   */
  const handleLoginForm: SubmitHandler<{
    email: string
    password: string
    token: number
  }> = useCallback(
    async (data) => {
      try {
        const results = await login({ ...data }).unwrap()
        if (results?.otpEnabled && !data.token) {
          setOtpEnabled(true)
        } else {
          const redirectTo = state?.from?.pathname || '/dashboard'
          navigate(redirectTo, { replace: true })
        }
      } catch (error) {
        reset({ email: '', password: '' })
        setOtpEnabled(false)
        showError(error)
      }
    },
    [login, navigate, reset, setOtpEnabled, showError, state]
  )

  const handleClickShowPassword = useCallback(() => {
    setShowPassword((prev) => !prev)
  }, [])

  const handleSignup = useCallback(() => {
    window.location.href = SIGNUP_URL
  }, [])

  const StyledTextField = styled(TextField)(({ theme }) => ({
    width: '100%',
    '& .MuiInputBase-root': {
      borderRadius: 'var(--border-radius)',
    },
    '& .MuiInputBase-input': {
      borderColor: 'var(--border-primary)',
      borderRadius: 'var(--border-radius)',
      margin: '0',
    },
    '& .MuiPaper-root': {
      '& .MuiMenu-paper': {
        borderRadius: '1px',
      },
    },
  }))

  const StyledAside = styled('aside')(({ theme }) => ({
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  }))

  const StyledMainContainer = styled('section')(({ theme }) => ({
    width: 'auto',
    minWidth: 'initial',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      minWidth: 'unset',
    },
  }))

  const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
    color: 'var(--content-secondary)',
    fontSize: '14px',
    marginBottom: '0px',
  }))

  const StyledInputAdornment = styled(InputAdornment)`
    margin-left: 0;
    .MuiIconButton-root {
      background-color: var(--background-secondary);
      color: var(--content-action);
      border-radius: 0;
      &:hover {
        background-color: var(--background-secondary);
      }
    }
  `

  return (
    <main className='login__main'>
      <StyledMainContainer className='login__main-container'>
        <TopLogo />
        <div className='login__form-container'>
          {state?.isExpired && (
            <p className='login__expired-token'>
              {t('login.MESSAGE_EPIRED_TOKEN')}
            </p>
          )}
          <h1>{t('login.HEADER')}</h1>
          <form onSubmit={handleSubmit(handleLoginForm)}>
            <div>
              {!otpEnabled && (
                <>
                  <div className='login__form-container--field'>
                    <StyledTextField
                      required
                      id='email'
                      variant='outlined'
                      autoComplete='username'
                      placeholder={t('login.EMAIL')}
                      {...register('email', {
                        required: t('login.VALID_EMAIL_REQUIRED'),
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: t('login.VALID_EMAIL_REQUIRED'),
                        },
                      })}
                    />
                  </div>
                  <div className='login__form-container--field'>
                    <StyledTextField
                      required
                      id='password'
                      variant='outlined'
                      type={showPassword ? 'text' : 'password'}
                      autoComplete='current-pasword'
                      placeholder={t('login.PASSWORD')}
                      {...register('password', {
                        required: t('login.PASSWORD_REQUIRED'),
                      })}
                      InputProps={{
                        endAdornment: (
                          <StyledInputAdornment position='end'>
                            <IconButton
                              onClick={handleClickShowPassword}
                              edge='end'>
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </StyledInputAdornment>
                        ),
                      }}
                    />
                  </div>
                </>
              )}

              {/* IF OTP IS ENABLED, SHOW THE INPUT FIELD*/}
              {otpEnabled && (
                <div className='login__form-container--field'>
                  <StyledInputLabel shrink htmlFor='token'>
                    {t('login.OTP_CODE')}
                  </StyledInputLabel>
                  <StyledTextField
                    required
                    id='token'
                    variant='outlined'
                    type='number'
                    {...register('token', {
                      required: t('login.OTP_CODE_REQUIRED'),
                    })}
                  />
                </div>
              )}

              <div className='login__form-container--field-button'>
                <Button type='submit' variant='contained'>
                  {t('login.LOGIN_BUTTON')}
                </Button>
              </div>
              <div className='login__form-container__footer-wrapper'>
                <Link to='/forgot-password' className='login__forgot-password'>
                  {t('login.FORGOT_PASSWORD')}
                </Link>

                <div className='login__form-container__footer-wrapper__signup-wrapper'>
                  <hr className='custom-line' />
                  <p className='login__form-container__footer-wrapper__signup-wrapper--signup-question'>
                    {t('login.SIGNUP_QUESTION')}
                  </p>
                  <Button
                    sx={{
                      width: '100%',
                      color: 'var(--content-action)',
                      border: 'solid 1px var(--border-primary)',
                      textTransform: 'unset',
                    }}
                    color='primary'
                    variant='text'
                    onClick={handleSignup}>
                    {t('login.SIGNUP')}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className='login__footer--container'>
          <FooterLogo />
          <span>{`© ${new Date().getFullYear()} INTERCLOUD`}</span>
          <span className='login__footer--address'>{t('login.ADDRESS')}</span>
          <div className='login__footer--links-container'>
            <a
              href='https://www.intercloud.com'
              target='_blank'
              rel='noreferrer'>
              www.intercloud.com
            </a>
            <Link to='/legal'>{t('login.LEGAL_MENTIONS')}</Link>
          </div>
        </div>
      </StyledMainContainer>
      <StyledAside className='login__aside' />
    </main>
  )
}
