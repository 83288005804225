// -- Import NPMS
import { useCallback } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

// -- Import Components
import useCurrentAccount from '../../../hooks/useCurrentAccount'
import {
  useGetWorkspaceByIdV2Query,
  useUpdateWorkspaceV2Mutation,
} from '../../../slices/workspacesApiSliceV2'
// -- Import Assets
import {
  StyledActionButton,
  StyledInputLabel,
  StyledTextField,
} from '../../../components/forms/StyledFormComponents'
import { usePermissions } from '../../../hooks/usePermissions'
import { useSnackbar } from '../../../hooks/useSnackbar'
import { ResourceType } from '../../../slices/authorizationsApiSlice'

interface inputForm {
  id: string
  name: string
  description: string
}

export default function SettingsTab() {
  const { showError, showSuccess } = useSnackbar()
  const { t } = useTranslation()
  const { currentAccount } = useCurrentAccount()
  const { workspaceId } = useParams()
  const { data: workspaceData } = useGetWorkspaceByIdV2Query({
    accountId: currentAccount?.id,
    workspaceId: workspaceId,
  })

  const [updateWorkspace] = useUpdateWorkspaceV2Mutation()
  const { register, handleSubmit } = useForm<inputForm>({
    shouldUseNativeValidation: true,
  })

  const onSubmit: SubmitHandler<inputForm> = useCallback(
    async (data) => {
      const { ...newData } = data
      newData.id = workspaceId

      try {
        await updateWorkspace({
          accountId: currentAccount?.id,
          workspace: newData,
        })
        showSuccess(
          t('workspace.messages.WORKSPACE_HAS_BEEN_UPDATED_WITH_SUCCESS')
        )
      } catch (e) {
        showError(e)
      }
    },
    [
      currentAccount?.id,
      t,
      updateWorkspace,
      workspaceId,
      showSuccess,
      showError,
    ]
  )

  const { allowed: allowToUpdate } = usePermissions(
    ResourceType.Workspace,
    workspaceId,
    'update_workspace'
  )

  if (!workspaceData) {
    return null
  }

  return (
    <div className='workspace__container--tabs'>
      <form
        className='workspace__form-container'
        onSubmit={handleSubmit(onSubmit)}>
        <div className='settings__tabs__form'>
          <div className='settings__tabs__form-input-content'>
            <StyledInputLabel shrink htmlFor='workspace-form-name'>
              {t('workspace.NAME')}
            </StyledInputLabel>
            <StyledTextField
              required
              id='workspace-form-name'
              variant='outlined'
              size='small'
              defaultValue={workspaceData?.data?.name}
              slotProps={{
                htmlInput: {
                  form: {
                    autocomplete: 'off',
                  },
                },
              }}
              {...register('name', {
                required: t('workspace.NAME_REQUIRED'),
              })}
            />
          </div>
          <div className='settings__tabs__form-input-content'>
            <StyledInputLabel shrink htmlFor='workspace-form-description'>
              {t('workspace.DESCRIPTION')}
            </StyledInputLabel>
            <StyledTextField
              id='workspace-form-description'
              variant='outlined'
              size='small'
              defaultValue={workspaceData?.data?.description}
              slotProps={{
                htmlInput: {
                  form: {
                    autocomplete: 'off',
                  },
                },
              }}
              {...register('description')}
            />
          </div>
        </div>
        <footer>
          <div className='workspace__footer'>
            <div className='workspace__footer-action'>
              <StyledActionButton
                type='submit'
                color='primary'
                variant='contained'
                disabled={!allowToUpdate}
                sx={{
                  '&:disabled': {
                    color: 'var(--decorative-selection)',
                    border: '1px solid var(--decorative-selection)',
                  },
                }}>
                {t('actions.common.SAVE')}
              </StyledActionButton>
            </div>
          </div>
        </footer>
      </form>
    </div>
  )
}
