import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import './style.scss'

import { useCallback } from 'react'
import TopLogo from '../../assets/logos/horizontal-small.svg?react'
import { StyledActionButton } from '../../components/forms/StyledFormComponents'

const LegalPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleBackClick = useCallback(() => {
    navigate(-1)
  }, [navigate])

  return (
    <main className='login__main'>
      <section className='login__main-container'>
        <div className='login__top-container'>
          <TopLogo />
        </div>
      </section>

      <section className='login__legal-wrapper'>
        <h1>{t('legalMentions.title')}</h1>
        <p>
          {t('legalMentions.content')
            .split('\n')
            .map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
        </p>
        <StyledActionButton
          sx={{
            width: 98,
          }}
          color='primary'
          variant='contained'
          onClick={handleBackClick}>
          {t('legalMentions.backButton')}
        </StyledActionButton>
      </section>
    </main>
  )
}

export default LegalPage
