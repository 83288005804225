// -- Import NPMS
import { Button } from '@mui/material'

// -- Import Assets
import './style.scss'

export type NoElementYetProps = {
  handleClickButtonBanner?: () => void
  noElementMessage: string
  noElementButtonText?: string
}

export default function NoElementYet({
  handleClickButtonBanner,
  noElementMessage,
  noElementButtonText,
}: NoElementYetProps) {
  return (
    <div className='no__element'>
      <div className='no__element--content'>
        <div className='no__element--content-text'>{noElementMessage}</div>
        {noElementButtonText ? (
          <Button
            className='no__element--content-button'
            variant='contained'
            onClick={handleClickButtonBanner}>
            {noElementButtonText}
          </Button>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}
