// -- Import NP%M
import { fetchEventSource } from '@microsoft/fetch-event-source'
import { Box, IconButton } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import {
  API_PATH_NOTIFICATIONS,
  REACT_APP_API_URL,
} from '../../environments/env'

// -- Import Assets
import CloseSidebar from '../../assets/icons-24/close-sidebar.svg?react'
import Documentation from '../../assets/icons-24/document.svg?react'
import OpenSidebar from '../../assets/icons-24/open-sidebar.svg?react'
import Support from '../../assets/icons-24/support.svg?react'
import Dashboard from '../../assets/icons-io-v1/dashboard.svg?react'
import Logout from '../../assets/icons-io-v1/logout.svg?react'
import Services from '../../assets/icons-io-v1/ports.svg?react'
import Settings from '../../assets/icons-io-v1/settings.svg?react'
import Collaborator from '../../assets/icons-io-v1/users.svg?react'
import Organization from '../../assets/icons-io-v1/workspaces.svg?react'
import SwitchAccountIcon from '../../assets/imgs/switch-icon.svg?react'
import InterCloud from '../../assets/logos/horizontal-small-white.svg?react'
import DrawerAction from '../../components/drawer/DrawerActions'
import Visible from '../../components/permissions/Visible'
import useCurrentAccount from '../../hooks/useCurrentAccount'
import { useSideBar } from '../../hooks/useSideBar'
import { apiSlice } from '../../slices/apiSlice'
import { useSelfQuery } from '../../slices/authApiSlice'
import { selectCurrentToken } from '../../slices/authSlice'
import { ResourceType } from '../../slices/authorizationsApiSlice'
import { SseEventTypes } from '../../slices/types'
import Layout from '../Layout'
import UserAvatar from '../User/Avatar'
import './styles.scss'

import classnames from 'classnames'

type EventData = {
  accountId: string
  workspaceId: string
  time: string
} & (
  | {
      transport: {
        id: string
        state: string
      }
    }
  | {
      node: {
        id: string
        state: string
      }
    }
)

export default function Sidebar() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { currentAccount } = useCurrentAccount()

  //const [sidebarState, setSidebarState] = useState(true)

  const { opened: sideBarOpened, toggleSideBar } = useSideBar()

  const [openDrawer, setOpenDrawer] = useState(false)

  // --------------------------------------------------
  // ----- Get Self Data, and fix refetchOnMount issue
  const token = useSelector(selectCurrentToken)
  const [tokenState, setTokenState] = useState(token)
  const { data: MeData, refetch } = useSelfQuery(null, {
    skip: !token,
  })

  useEffect(() => {
    if (tokenState !== token) {
      refetch()
      setTokenState(token)
    }
  }, [tokenState, token, refetch])

  // ----- END Get Self Data, and fix refetchOnMount issue
  // -----------------------------------------------------

  // --------------------------------------------------
  // ----- SSE NOTOIICATIONS --------------------------
  useEffect(() => {
    if (!currentAccount) return
    const ctrl = new AbortController()
    const fetchData = async () => {
      await fetchEventSource(
        `${REACT_APP_API_URL}${API_PATH_NOTIFICATIONS}/sse?stream=account.${currentAccount?.id}`,
        {
          method: 'GET',
          signal: ctrl.signal,
          headers: {
            Accept: 'text/event-stream',
            authorization: `Bearer ${token}`,
          },
          onopen(res) {
            return new Promise<void>((resolve) => {
              if (res.ok && res.status === 200) {
                console.log('Connection made ', res)
              } else {
                console.log('Connection SSE error ', res)
              }

              resolve()
            })
          },
          onmessage(eventData) {
            switch (eventData.event) {
              case `${SseEventTypes.ElementUpdated}`:
                const data: EventData = JSON.parse(eventData.data)
                const { workspaceId: id } = data
                if ('transport' in data) {
                  dispatch(
                    apiSlice.util.invalidateTags([
                      { type: 'Transports', id: data.transport.id },
                    ])
                  )
                }
                if ('node' in data) {
                  dispatch(
                    apiSlice.util.invalidateTags([
                      { type: 'Nodes', id: data.node.id },
                    ])
                  )
                }

                dispatch(
                  apiSlice.util.invalidateTags([{ type: 'Workspaces', id }])
                )
                break
            }
          },
          onclose() {
            console.log('Connection closed by the server')
          },
          onerror(err) {
            console.log('There was an error from server', err)
          },
        }
      )
    }
    fetchData()
    return () => {
      ctrl.abort()
    }
  }, [currentAccount, dispatch, token])

  // ----- END SSE NOTOIICATIONS ----------------------
  // --------------------------------------------------

  const listItems = useMemo(
    () => [
      {
        name: t('sidebar.DASHBOARD'),
        icon: Dashboard,
        href: 'dashboard',
        permission: 'get_account',
      },
      {
        name: t('sidebar.WORKSPACES'),
        icon: Organization,
        href: 'workspaces',
        permission: 'list_workspaces',
      },
      {
        name: t('sidebar.PORTS'),
        icon: Services,
        href: 'ports',
        permission: 'list_ports',
      },
      {
        name: t('sidebar.USERS'),
        icon: Collaborator,
        href: 'users',
        permission: 'list_account_user',
      },
    ],
    [t]
  )
  const settingsItems = useMemo(
    () => [
      {
        name: t('sidebar.DOCUMENTATION'),
        icon: Documentation,
        href: 'https://docs.autonomi-platform.com/',
        target: '_blank',
      },
      {
        name: t('sidebar.SUPPORT'),
        icon: Support,
        href: 'https://intercloud.atlassian.net/servicedesk/customer/portal/21',
        target: '_blank',
      },
      {
        name: t('sidebar.SETTINGS'),
        icon: Settings,
        href: 'settings',
        target: '',
      },
      { name: t('sidebar.LOGOUT'), icon: Logout, href: 'logout', target: '' },
    ],
    [t]
  )

  const handleSwitchAccount = useCallback(() => {
    setOpenDrawer(true)
  }, [])

  if (!MeData || !currentAccount) {
    return null
  }

  return (
    <>
      <section
        className={classnames('sidebar__wrapper', {
          'sidebar__wrapper--closed': !sideBarOpened,
        })}
        id='sidebar-wrapper'>
        <div
          className={classnames('sidebar__container', {
            'sidebar__container--closed': !sideBarOpened,
          })}>
          <Box sx={{ width: '100%' }}>
            <div
              className={classnames('sidebar__header', {
                'sidebar__header--closed': !sideBarOpened,
              })}>
              <div
                className={classnames('sidebar__header--container-logo', {
                  'sidebar__header--container-logo-closed': !sideBarOpened,
                })}>
                <InterCloud
                  className={classnames('sidebar__header--logo', {
                    'sidebar__header--logo-closed': !sideBarOpened,
                  })}
                />
                <span
                  className={classnames('sidebar__header--name', {
                    'sidebar__header--name-closed': !sideBarOpened,
                  })}
                />
              </div>
              <button type='button' onClick={toggleSideBar}>
                {sideBarOpened ? <CloseSidebar /> : <OpenSidebar />}
              </button>
            </div>
          </Box>
          <div
            className={classnames('sidebar__account', {
              'sidebar__account--closed': !sideBarOpened,
            })}>
            <div className='sidebar__account-avatar-name-wrapper'>
              <div>
                <UserAvatar userName={MeData?.name} />
              </div>

              <div
                className={classnames('sidebar__account-name', {
                  'sidebar__account-name--closed': !sideBarOpened,
                })}>
                <div>{currentAccount.name}</div>
                <div className='surname'>{MeData.name}</div>
              </div>
            </div>
            <Visible
              resource={ResourceType.Account}
              resourceId={'*'}
              action={'list_accounts'}>
              <IconButton onClick={handleSwitchAccount}>
                <SwitchAccountIcon />
              </IconButton>
            </Visible>
          </div>
          <nav
            className={classnames('sidebar__nav', {
              'sidebar__nav--closed': !sideBarOpened,
            })}>
            <ul
              className={classnames('sidebar__nav-list', {
                'sidebar__nav-list--closed': !sideBarOpened,
              })}>
              {listItems.map(
                ({ name, href, icon: MenuIcon, permission }, idx) => (
                  <Visible
                    key={href}
                    resource={ResourceType.Account}
                    resourceId={currentAccount?.id}
                    action={permission}>
                    <NavLink
                      to={`${href}`}
                      className={({ isActive }) =>
                        classnames('sidebar__nav-link', {
                          'sidebar__nav-link--active': isActive,
                        })
                      }>
                      <li
                        className={classnames('sidebar__nav-link--li', {
                          'sidebar__nav-link--closed': !sideBarOpened,
                        })}>
                        <MenuIcon
                          className={classnames('sidebar__nav-icon', {
                            'sidebar__nav-icon--closed': !sideBarOpened,
                          })}
                        />
                        <div
                          className={classnames('sidebar__nav-text', {
                            'sidebar__nav-text--closed': !sideBarOpened,
                          })}>
                          {name}
                        </div>
                      </li>
                    </NavLink>
                  </Visible>
                )
              )}
            </ul>
            <ul
              className={classnames('sidebar__nav-settings-list', {
                'sidebar__nav-settings-list--closed': !sideBarOpened,
              })}>
              {settingsItems.map(
                ({ name, href, icon: MenuIcon, target }, idx) => (
                  <NavLink
                    key={href}
                    to={`${href}`}
                    target={target}
                    className={({ isActive }) =>
                      isActive
                        ? 'sidebar__nav-link sidebar__nav-link--active'
                        : 'sidebar__nav-link'
                    }>
                    <li
                      className={classnames('sidebar__nav-link--li', {
                        'sidebar__nav-link--closed': !sideBarOpened,
                      })}>
                      <MenuIcon
                        className={classnames('sidebar__nav-icon', {
                          'sidebar__nav-icon--closed': !sideBarOpened,
                        })}
                      />
                      <div
                        className={classnames('sidebar__nav-text', {
                          'sidebar__nav-text--closed': !sideBarOpened,
                        })}>
                        {name}
                      </div>
                    </li>
                  </NavLink>
                )
              )}
            </ul>
          </nav>
        </div>
      </section>
      <section
        className={classnames('sidebar__layout', {
          'sidebar__layout--closed': !sideBarOpened,
        })}>
        <Layout />
      </section>
      <DrawerAction
        pageView='showAccounts'
        setOpenDrawer={setOpenDrawer}
        openDrawer={openDrawer}
      />
    </>
  )
}
