// -- Import NPM
import {
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  useMediaQuery,
} from '@mui/material'
import { useThemeSwitcher } from 'react-css-theme-switcher'
import { useTranslation } from 'react-i18next'

export default function GeneralSettings() {
  const { switcher } = useThemeSwitcher()
  const { t } = useTranslation()
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const storedTheme = localStorage.getItem('selectedTheme') ?? 'system'

  const handleThemeChange = (event) => {
    let theme = event.target.value
    if (theme === 'system') {
      localStorage.removeItem('theme')
      theme = prefersDarkMode ? 'dark' : 'light'
    } else {
      localStorage.setItem('theme', theme)
    }
    switcher({ theme })
  }

  return (
    <>
      <Divider className='settings__header-divider' />
      <div className='settings__container'>
        <div className='settings__appearance-content'>
          <div className='settings__mode-label'>{t('settings.SET_MODE')}:</div>
          <FormControl>
            <RadioGroup
              name='radio-buttons-group'
              onChange={handleThemeChange}
              defaultValue={storedTheme}
              className='settings__radio-group'>
              <FormControlLabel
                value='light'
                control={
                  <Radio
                    disableRipple
                    sx={{
                      color: 'var(--background-inverse)',
                    }}
                  />
                }
                label={t('settings.LIGHT')}
              />
              <FormControlLabel
                value='dark'
                control={
                  <Radio
                    disableRipple
                    sx={{
                      color: 'var(--background-inverse)',
                    }}
                  />
                }
                label={t('settings.DARK')}
              />
              <FormControlLabel
                value='system'
                control={
                  <Radio
                    disableRipple
                    sx={{
                      color: 'var(--background-inverse)',
                    }}
                  />
                }
                label={t('settings.SYSTEM')}
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
    </>
  )
}
