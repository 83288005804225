import type { EdgeProps } from '@xyflow/react'
import { BaseEdge, getBezierPath } from '@xyflow/react'
import type { FC } from 'react'
import { administrativeStateColor } from '../../../../../../hooks/administrativeStateColor'
import type { LogicalServiceProviderEdge } from '../types'

const ServiceProviderLink: FC<EdgeProps<LogicalServiceProviderEdge>> = ({
  id,
  markerEnd,
  markerStart,
  style = {},
  sourceX,
  sourceY,
  sourcePosition,
  targetX,
  targetY,
  targetPosition,
  data,
}) => {
  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  })

  const { administrativeState } = data
  return (
    <BaseEdge
      id={id}
      key={id}
      path={edgePath}
      markerStart={markerStart}
      markerEnd={markerEnd}
      style={{
        ...style,
        stroke: administrativeStateColor(administrativeState),
        strokeDasharray: '10,10',
      }}
    />
  )
}

export default ServiceProviderLink
