import { Box, Typography } from '@mui/material'
import { addDays, parse } from 'date-fns'
import { useCallback } from 'react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { MainDrawer } from '../../../../components/drawer/MainDrawer'
import DatePickerInput from '../../../../components/forms/DatePickerInput'
import {
  StyledActionButton,
  StyledInputLabel,
  StyledTextField,
} from '../../../../components/forms/StyledFormComponents'
import useCurrentAccount from '../../../../hooks/useCurrentAccount'
import { useSnackbar } from '../../../../hooks/useSnackbar'
import { useCreatePersonalTokenMutation } from '../../../../slices/accountApiSlice'
import { useSelfQuery } from '../../../../slices/authApiSlice'

type CreatePersonalTokenProps = {
  onClose: () => void
}

type InputForm = {
  label: string
  description: string
  expiredAt: string
}

const formatExpiredAt = 'dd/MM/yyyy'

const CreatePersonalToken = ({ onClose }: CreatePersonalTokenProps) => {
  const { showError, showSuccess } = useSnackbar()
  const { t } = useTranslation()
  const [createToken, { isLoading }] = useCreatePersonalTokenMutation()

  const { currentAccount } = useCurrentAccount()
  const { data: meData } = useSelfQuery()

  const {
    control,
    register,
    handleSubmit,
    formState: { isValid },
    trigger,
  } = useForm<InputForm>()

  const onSubmit: SubmitHandler<InputForm> = useCallback(
    async ({ description, expiredAt, label }) => {
      try {
        await createToken({
          accountId: currentAccount.id,
          userId: meData.id,
          body: {
            label,
            description,
            expiredAt: parse(expiredAt, formatExpiredAt, new Date()),
          },
        }).unwrap()
        showSuccess('')
      } catch (error) {
        showError(error)
      } finally {
        onClose()
      }
    },
    [
      createToken,
      currentAccount?.id,
      meData?.id,
      onClose,
      showError,
      showSuccess,
    ]
  )

  const submitForm = useCallback(async () => {
    const isValid = trigger()
    if (isValid) {
      await handleSubmit(onSubmit)()
    }
  }, [handleSubmit, onSubmit, trigger])

  return (
    <MainDrawer
      onClose={onClose}
      body={
        <Box flexGrow={1} className='wrapper__content'>
          <Typography
            variant='h5'
            gutterBottom
            style={{
              fontWeight: 600,
              fontSize: '18px',
              lineHeight: '21px',
              color: 'var(--content-primary)',
              marginBottom: '16px',
            }}>
            {t('settings.tokens.CREATE_TITLE')}
          </Typography>
          <Box<'form'>
            component='form'
            noValidate
            autoComplete='off'
            display='flex'
            flexDirection='column'
            gap='2rem'>
            <Box>
              <StyledInputLabel htmlFor='token-form-name'>
                {t('settings.tokens.CREATE_NAME_LABEL')}
              </StyledInputLabel>
              <StyledTextField
                id='token-form-name'
                variant='outlined'
                required
                size='small'
                {...register('label', {
                  required: t('element.FIELD_REQUIRED'),
                })}
                slotProps={{
                  htmlInput: {
                    form: {
                      autocomplete: 'off',
                    },
                  },
                }}
              />
            </Box>
            <Box>
              <StyledInputLabel htmlFor='token-form-date'>
                {t('settings.tokens.CARD_EXPIRES')}
              </StyledInputLabel>
              <DatePickerInput
                rules={{ required: t('element.FIELD_REQUIRED') }}
                id='token-form-date'
                name='expiredAt'
                control={control}
                minDate={addDays(new Date(), 1)}
                maxDate={addDays(new Date(), 365)}
              />
            </Box>
            <Box>
              <StyledInputLabel htmlFor='token-form-comment'>
                {t('settings.tokens.CREATE_COMMENT_LABEL')}
              </StyledInputLabel>
              <StyledTextField
                id='token-form-comment'
                variant='outlined'
                size='small'
                {...register('description')}
                slotProps={{
                  htmlInput: {
                    form: {
                      autocomplete: 'off',
                    },
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
      }
      footer={
        <Box display='flex' gap='1%' flexGrow={1}>
          <StyledActionButton
            sx={{
              width: '49.5%',
              backgroundColor: 'var(--background-tertiary)',
              '&:hover': {
                backgroundColor: 'var(--background-secondary)',
              },
            }}
            color='primary'
            variant='text'
            onClick={onClose}>
            {t('element.DISCARD')}
          </StyledActionButton>
          <StyledActionButton
            sx={{ width: '49.5%', marginLeft: '1%' }}
            type='submit'
            color='primary'
            variant='contained'
            disabled={!isValid}
            onClick={submitForm}>
            {t('element.CREATE')}
          </StyledActionButton>
        </Box>
      }
      withBackdrop
      backdropLoading={isLoading}
    />
  )
}

export default CreatePersonalToken
