import type { EdgeProps } from '@xyflow/react'
import { BaseEdge, getBezierPath } from '@xyflow/react'
import type { FC } from 'react'
import type { LogicalPhysicalEdge } from '../types'

const PhysicalPortLink: FC<EdgeProps<LogicalPhysicalEdge>> = ({
  id,
  markerEnd,
  markerStart,
  style = {},
  sourceX,
  sourceY,
  sourcePosition,
  targetX,
  targetY,
  targetPosition,
}) => {
  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  })

  return (
    <BaseEdge
      id={id}
      key={id}
      path={edgePath}
      markerEnd={markerEnd}
      style={{
        ...style,
        stroke: 'var(--chips-deployed-full)',
        strokeDasharray: '10,10',
      }}
      markerStart={markerStart}
    />
  )
}

export default PhysicalPortLink
