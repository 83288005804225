import type { UseNodesInitializedOptions } from '@xyflow/react'
import { Position, useNodesInitialized, useReactFlow } from '@xyflow/react'
import type { ElkNode, ElkPort } from 'elkjs'
import ELK from 'elkjs'
import { useCallback, useEffect } from 'react'
import { useSnackbar } from '../../../../../../../hooks/useSnackbar'
import type { LogicalEdges, LogicalNodes } from '../../types'
import { LogicalNodeType } from '../../types'
import type { ELKPortSideValue, LayoutDirectionType, LayoutItem } from './types'
import useLayoutForm from './useLayoutForm'

const options: UseNodesInitializedOptions = {
  includeHiddenNodes: false,
}

const ELKPortSideOption = 'org.eclipse.elk.port.side'

const PortSide = (
  type: 'source' | 'target',
  direction: LayoutDirectionType
): ELKPortSideValue => {
  if (type === 'source') {
    return direction === 'RIGHT' ? 'EAST' : 'SOUTH'
  }
  return direction === 'RIGHT' ? 'WEST' : 'NORTH'
}

const PortPosition: { [side in ELKPortSideValue]: Position } = {
  EAST: Position.Right,
  NORTH: Position.Top,
  SOUTH: Position.Bottom,
  WEST: Position.Left,
}

const nodePorts = (node: LogicalNodes, layout: LayoutItem): ElkPort[] => {
  const direction = layout.layoutOptions['org.eclipse.elk.direction']

  const { id, type } = node
  switch (type) {
    case LogicalNodeType.Cloud:
      return [
        {
          id: `${id}-target`,
          layoutOptions: {
            [ELKPortSideOption]: PortSide('target', direction),
          },
        },
        {
          id: `${id}-source`,
          layoutOptions: {
            [ELKPortSideOption]: PortSide('source', direction),
          },
        },
      ]
    case LogicalNodeType.Access:
      return [
        {
          id: `${id}-source`,
          layoutOptions: {
            [ELKPortSideOption]: PortSide('source', direction),
          },
        },
        {
          id: `${id}-target`,
          layoutOptions: {
            [ELKPortSideOption]: PortSide('target', direction),
          },
        },
      ]
    case LogicalNodeType.Transport:
      return [
        {
          id: `${id}-source`,
          layoutOptions: {
            [ELKPortSideOption]: PortSide('source', direction),
          },
        },
        {
          id: `${id}-target`,
          layoutOptions: {
            [ELKPortSideOption]: PortSide('target', direction),
          },
        },
      ]
    case LogicalNodeType.PhysicalPort:
      return [
        {
          id: `${id}-source`,
          layoutOptions: {
            [ELKPortSideOption]: PortSide('source', direction),
          },
        },
      ]
    case LogicalNodeType.ServiceProvider:
      return [
        {
          id: `${id}-target`,
          layoutOptions: {
            [ELKPortSideOption]: PortSide('target', direction),
          },
        },
      ]
    default:
      return []
  }
}

const defaultNodeLayoutOptions = {}

const getLayoutedNodes = async (
  nodes: LogicalNodes[],
  edges: LogicalEdges[],
  direction: LayoutDirectionType,
  layout: LayoutItem
): Promise<LogicalNodes[]> => {
  const { layoutOptions } = layout

  if ('org.eclipse.elk.direction' in layoutOptions) {
    layoutOptions['org.eclipse.elk.direction'] = direction
  }

  const graph: ElkNode = {
    id: 'root',
    children: nodes.map((node) => {
      const { id, width, height } = node

      let isLinked = edges.some(({ source, target }) => {
        return source === id || target === id
      })
      let layoutOptions = { ...defaultNodeLayoutOptions }

      if (isLinked) {
        layoutOptions = {
          ...layoutOptions,
          'org.eclipse.elk.portConstraints': 'FIXED_SIDE',
        }
      }

      return {
        id,
        width,
        height,
        ports: isLinked ? nodePorts(node, layout) : [],
        layoutOptions,
      }
    }),
    edges: edges.map(({ id, source, target, targetHandle, sourceHandle }) => ({
      id,
      sources: [sourceHandle ?? `${source}-source`],
      targets: [targetHandle ?? `${target}-target`],
    })),
    layoutOptions,
  }

  // Debug with https://rtsys.informatik.uni-kiel.de/elklive/json.html
  // console.log({ graph })

  let layoutedGraph: ElkNode

  // DEBUG to know which options are available
  // let optionsGraph = await elk.knownLayoutOptions()
  // optionsGraph = optionsGraph.filter(({ targets = [] }) => {
  //     return targets.includes('PORTS')
  //   })
  // console.log({ optionsGraph })

  layoutedGraph = await elk.layout(graph, { layoutOptions: {} })

  // Debug with https://rtsys.informatik.uni-kiel.de/elklive/json.html
  // console.log({ layoutedGraph })

  return nodes.map((node) => {
    const previousNode = layoutedGraph.children.find((ln) => ln.id === node.id)

    const { height, width, x, y, ports } = previousNode

    const targetPort = ports.find(({ id }) => id.includes('target'))
      ?.layoutOptions[ELKPortSideOption] as ELKPortSideValue | undefined
    const sourcePort = ports.find(({ id }) => id.includes('source'))
      ?.layoutOptions[ELKPortSideOption] as ELKPortSideValue | undefined

    const targetPosition = targetPort ? PortPosition[targetPort] : undefined
    const sourcePosition = sourcePort ? PortPosition[sourcePort] : undefined

    return {
      ...node,
      height,
      width,
      targetPosition,
      sourcePosition,
      position: {
        x,
        y,
      },
    }
  })
}

const elk = new ELK()

const useLogicalLayout = () => {
  const nodesInitialized = useNodesInitialized(options)
  const { selectedDirection, selectedLayout } = useLayoutForm()

  // @FIXME: change to error refacto
  const { showErrorWithMessage } = useSnackbar()

  const { getNodes, getEdges, setNodes } = useReactFlow<
    LogicalNodes,
    LogicalEdges
  >()

  const layoutNodes = useCallback(async () => {
    let layoutedNodes: LogicalNodes[] = []
    try {
      layoutedNodes = await getLayoutedNodes(
        getNodes(),
        getEdges(),
        selectedDirection,
        selectedLayout
      )
    } catch (error) {
      console.error(error)
      showErrorWithMessage('Error while rendering the diagram')
    }
    setNodes(layoutedNodes)
  }, [
    getEdges,
    getNodes,
    selectedDirection,
    selectedLayout,
    setNodes,
    showErrorWithMessage,
  ])

  useEffect(() => {
    let id: number
    if (nodesInitialized) {
      id = requestAnimationFrame(() => layoutNodes())
    }
    return () => {
      cancelAnimationFrame(id)
    }
  }, [layoutNodes, nodesInitialized])
}

export default useLogicalLayout
