import React from 'react'

interface Props {
  title: string
  value: string | number | React.ReactNode
}

export default function LogicalCardRows({ title, value }: Props) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 4,
      }}>
      <div
        style={{
          paddingRight: '15px',
          minWidth: 'fit-content',
        }}>
        {title}
      </div>
      <div
        style={{
          textWrap: 'nowrap',
          textOverflow: 'ellipsis',
        }}>
        {value}
      </div>
    </div>
  )
}
