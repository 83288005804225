import type { Dispatch, FC, PropsWithChildren, SetStateAction } from 'react'
import { createContext, useCallback, useContext, useState } from 'react'

interface SidbarState {
  opened: boolean
  toggleSideBar: () => void
  setOpened: Dispatch<SetStateAction<boolean>>
}

const SideBarContext = createContext<SidbarState>({
  opened: true,
  toggleSideBar: () => {},
  setOpened: () => {},
})

export const useSideBar = () => {
  const { opened, setOpened, toggleSideBar } = useContext(SideBarContext)
  return { opened, setOpened, toggleSideBar }
}

export const SideBarProvider: FC<PropsWithChildren> = ({ children }) => {
  const [opened, setOpened] = useState(true)
  const toggleSideBar = useCallback(() => {
    setOpened((o) => !o)
  }, [])

  return (
    <SideBarContext.Provider value={{ opened, toggleSideBar, setOpened }}>
      {children}
    </SideBarContext.Provider>
  )
}
