import { Box, styled } from '@mui/material'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { MainDrawer } from '../../../../components/drawer/MainDrawer'
import { StyledActionButton } from '../../../../components/forms/StyledFormComponents'
import useCurrentAccount from '../../../../hooks/useCurrentAccount'
import { useSnackbar } from '../../../../hooks/useSnackbar'
import { useRevokePersonalTokenMutation } from '../../../../slices/accountApiSlice'
import { useSelfQuery } from '../../../../slices/authApiSlice'

type RevokeTokenProps = {
  onClose: () => void
}

const Title = styled(Box)`
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: var(--content-primary);
`

const Message = styled(Box)`
  font-size: 16px;
  line-height: 24px;
  color: var(--content-primary);
`

const TitleMessageWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 30px;
`

const RevokeToken = ({ onClose }: RevokeTokenProps) => {
  const { tokenId } = useParams()
  const { showError } = useSnackbar()

  const { currentAccount } = useCurrentAccount()

  const { data: MeData } = useSelfQuery()

  const { t } = useTranslation()
  const [revokeToken, { isLoading }] = useRevokePersonalTokenMutation()

  const handleRevoke = useCallback(async () => {
    try {
      await revokeToken({
        accountId: currentAccount.id,
        userId: MeData.id,
        tokenId,
      }).unwrap()
    } catch (error) {
      showError(error)
    } finally {
      onClose?.()
    }
  }, [MeData?.id, currentAccount?.id, onClose, revokeToken, showError, tokenId])

  if (!MeData || !currentAccount) {
    return null
  }

  return (
    <MainDrawer
      withBackdrop
      backdropLoading={isLoading}
      body={
        <TitleMessageWrapper>
          <Title>{t('settings.tokens.REVOKE_TITLE')}</Title>
          <Message>{t('settings.tokens.REVOKE_MESSAGE')}</Message>
        </TitleMessageWrapper>
      }
      closeButton
      footer={
        <Box display='flex' gap='1%' flexGrow={1}>
          <StyledActionButton
            sx={{
              backgroundColor: 'var(--background-tertiary)',
              '&:hover': {
                backgroundColor: 'var(--background-secondary)',
              },
            }}
            color='primary'
            variant='text'
            onClick={onClose}>
            {t('element.DISCARD')}
          </StyledActionButton>
          <StyledActionButton
            type='submit'
            variant='contained'
            color='primary'
            onClick={handleRevoke}
            disabled={isLoading}>
            {t('settings.tokens.REVOKE_BUTTON')}
          </StyledActionButton>
        </Box>
      }
      onClose={onClose}
    />
  )
}

export default RevokeToken
