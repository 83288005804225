// -- NPM IMPORTS

// -- COMPONENT IMPORTS
import type { FC } from 'react'
import WorkspacesList from './List'

// -- ASSETS IMPORTS
import './style.scss'

type WorkspaceProps = {}

// TODO: Fix overflow hidden pb
const Workspace: FC<WorkspaceProps> = () => {
  return <WorkspacesList />
}

export default Workspace
