import { API_PATH_ACCOUNTS } from '../environments/env'
import { apiSlice } from './apiSlice'

export const settingsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    mfaGenerate: builder.mutation({
      query: ({ accountId, userId, body }) => ({
        url: `${API_PATH_ACCOUNTS}/${accountId}/users/${userId}/otp/generate`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Self'],
    }),
    mfaVerify: builder.mutation({
      query: ({ accountId, userId, body }) => ({
        url: `${API_PATH_ACCOUNTS}/${accountId}/users/${userId}/otp/verify`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Self'],
    }),
    mfaDisable: builder.mutation({
      query: ({ accountId, userId, body }) => ({
        url: `${API_PATH_ACCOUNTS}/${accountId}/users/${userId}/otp/disable`,
        method: 'POST',
        body: {},
      }),
      invalidatesTags: ['Self'],
    }),
  }),
})

export const {
  useMfaGenerateMutation,
  useMfaVerifyMutation,
  useMfaDisableMutation,
} = settingsApiSlice
