import type { LayoutOptions } from 'elkjs'
import type { LayoutDirectionType, LayoutItem } from './types'
import { LayoutId } from './types'

export const physicalLinkId = (source: string, target: string) =>
  `${source}-${target}`

export const defaultDirection: LayoutDirectionType = 'RIGHT'

export const defaultLayoutOptions: LayoutOptions = {
  'org.eclipse.elk.debugMode': 'false',
  'org.eclipse.elk.spacing.edgeNode': '100',
  'org.eclipse.elk.spacing.nodeNode': '50',
  'org.eclipse.elk.layered.spacing.baseValue': '100',
  'org.eclipse.elk.direction': defaultDirection,
}

export const layouts: LayoutItem[] = [
  {
    id: LayoutId.Layered,
    label: 'Layered',
    layoutOptions: {
      ...defaultLayoutOptions,
      'org.eclipse.elk.algorithm': 'layered',
      'org.eclipse.elk.layered.spacing.nodeNodeBetweenLayers': '200',
      'org.eclipse.elk.layered.nodePlacement.strategy': 'SIMPLE',
      'org.eclipse.elk.layered.considerModelOrder.strategy': 'PREFER_NODES',
    },
  },
  {
    id: LayoutId.DiscoStress,
    label: 'Disco / stress',
    layoutOptions: {
      ...defaultLayoutOptions,
      'org.eclipse.elk.algorithm': 'disco',
      'org.eclipse.elk.disco.componentCompaction.componentLayoutAlgorithm':
        'stress',
      'org.eclipse.elk.stress.desiredEdgeLength': '300',
    },
  },
  {
    id: LayoutId.Stress,
    label: 'Stress',
    layoutOptions: {
      ...defaultLayoutOptions,
      'org.eclipse.elk.algorithm': 'stress',
      'org.eclipse.elk.stress.desiredEdgeLength': '300',
    },
  },
  {
    id: LayoutId.DiscoLayered,
    label: 'Disco / layered',
    layoutOptions: {
      ...defaultLayoutOptions,
      'org.eclipse.elk.algorithm': 'disco',
      'org.eclipse.elk.disco.componentCompaction.componentLayoutAlgorithm':
        'layered',
      'org.eclipse.elk.layered.spacing.nodeNodeBetweenLayers': '250',
      'org.eclipse.elk.layered.nodePlacement.strategy': 'SIMPLE',
      'org.eclipse.elk.layered.considerModelOrder.strategy': 'PREFER_NODES',
    },
  },
  {
    id: LayoutId.Force,
    label: 'Force',
    layoutOptions: {
      ...defaultLayoutOptions,
      'org.eclipse.elk.algorithm': 'force',
    },
  },
  {
    id: LayoutId.DiscoForce,
    label: 'Disco Force',
    layoutOptions: {
      ...defaultLayoutOptions,
      'org.eclipse.elk.algorithm': 'disco',
      'org.eclipse.elk.disco.componentCompaction.componentLayoutAlgorithm':
        'force',
    },
  },
]
