import {
  useNodesInitialized,
  useReactFlow,
  type UseNodesInitializedOptions,
} from '@xyflow/react'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { useGetWorkspaceByIdV2Query } from '../../../../../../../slices/workspacesApiSliceV2'
import Flow from '../../Flow'
import useFlowData from '../../Flow/hooks/useFlowData'
import useFlowStore from '../../Flow/hooks/useFlowStore'

interface WorkspaceProps {
  workspaceId: string
  accountId: string
}

const options: UseNodesInitializedOptions = {
  includeHiddenNodes: false,
}

const Workspace: FC<WorkspaceProps> = ({ workspaceId, accountId }) => {
  const { data } = useGetWorkspaceByIdV2Query(
    { accountId, workspaceId },
    {
      skip: !workspaceId || !accountId,
    }
  )

  const [, setCurrentWrk] = useState({ workspaceId, accountId })

  const { setNodes, setEdges } = useFlowStore()

  useEffect(() => {
    setNodes([])
    setEdges([])
    setCurrentWrk({ workspaceId, accountId })
  }, [workspaceId, accountId, setNodes, setEdges])

  const { nodes: dataNodes, edges: dataEdges } = useFlowData(data?.data)

  const { fitView, viewportInitialized } = useReactFlow()
  const nodesInitialized = useNodesInitialized(options)

  useEffect(() => {
    setNodes(dataNodes)
    setEdges(dataEdges)
  }, [dataEdges, dataNodes, setEdges, setNodes])

  useEffect(() => {
    let id: number
    if (nodesInitialized && viewportInitialized) {
      id = setTimeout(() => fitView(), 60)
    }
    return () => {
      clearTimeout(id)
    }
  }, [fitView, nodesInitialized, viewportInitialized])

  return <Flow />
}

export default Workspace
