import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
  type FC,
  type PropsWithChildren,
} from 'react'

interface DrawerContextState {
  opened: boolean
  openDrawer: () => void
  closeDrawer: () => void
}

const DrawerContext = createContext<DrawerContextState | null>(null)

interface DrawerProviderProps {}

export const DrawerProvider: FC<PropsWithChildren<DrawerProviderProps>> = ({
  children,
}) => {
  const [opened, setOpened] = useState(false)

  const openDrawer = useCallback(() => {
    setOpened(true)
  }, [])

  const closeDrawer = useCallback(() => {
    setOpened(false)
  }, [])

  const value = useMemo<DrawerContextState>(
    () => ({
      opened,
      openDrawer,
      closeDrawer,
    }),
    [closeDrawer, openDrawer, opened]
  )

  return (
    <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>
  )
}

const useDrawer = () => useContext(DrawerContext)

export default useDrawer
