// -- Import NPM
import Button from '@mui/material/Button'
import { t } from 'i18next'
import moment from 'moment'
import { useCallback, useState } from 'react'

// --- Import Assets
import DeleteModal from '../../../components/modals/DeleteModal'
import { useSnackbar } from '../../../hooks/useSnackbar'
import { useMfaDisableMutation } from '../../../slices/settingsApiSlice'
import type { User, mfaPayload } from '../../../slices/types'

type MfaConfigProps = {
  currentAccount: { id: string }
  MeData: User
  mfaGenerateData: mfaPayload
  setShowMfaState: React.Dispatch<React.SetStateAction<boolean>>
  setOpenSnackbar?: React.Dispatch<React.SetStateAction<boolean>>
  setSeverity?: React.Dispatch<React.SetStateAction<string>>
  setSnackbarMessage?: React.Dispatch<React.SetStateAction<string>>
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export default function MFADeviceList({
  currentAccount,
  MeData,
  mfaGenerateData,
  setShowMfaState,
  setLoading,
}: MfaConfigProps) {
  const { showError, showSuccess } = useSnackbar()
  const [disableMfa] = useMfaDisableMutation()

  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  const handleDeleteModal = () => {
    setOpenDeleteModal(true)
  }

  const handleClose = useCallback(
    async (value: boolean) => {
      if (value) {
        setLoading(true)
        try {
          await disableMfa({
            accountId: currentAccount?.id,
            userId: MeData?.id,
          }).unwrap()
          setLoading(false)
          setShowMfaState(false)
          showSuccess(t('settings.mfa.DISABLED_SUCCESS'))
        } catch (e) {
          setLoading(false)
          showError(e)
        }
      }
    },
    [
      disableMfa,
      currentAccount?.id,
      MeData?.id,
      setShowMfaState,
      setLoading,
      showSuccess,
      showError,
    ]
  )

  return (
    <>
      <div className='settings__mfa--header'>{t('settings.mfa.TITLE')}</div>

      <div className='settings__mfa--device'>
        <div className='settings__mfa--device-row'>
          <div className='settings__mfa--device-col'>
            {t('settings.mfa.DEVICE')}
          </div>
          <div className='settings__mfa--device-col'>
            {t('settings.mfa.CREATED_ON')}
          </div>
          <div className='settings__mfa--device-col'>
            {/* {t('settings.mfa.DISABLE')} */}
          </div>
        </div>

        <div className='settings__mfa--device-row'>
          <div className='settings__mfa--device-col'>
            {MeData?.device ? MeData?.device : mfaGenerateData?.device}
          </div>
          <div className='settings__mfa--device-col'>
            {moment(MeData?.otpCreatedAt).format('YYYY/MM/DD')}
          </div>
          <div className='settings__mfa--device-col'>
            <Button
              variant='contained'
              sx={{ minWidth: '100px' }}
              onClick={() => handleDeleteModal()}>
              {t('settings.mfa.DISABLE')}
            </Button>
          </div>
        </div>
      </div>
      <DeleteModal
        handleClose={handleClose}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        deleteDialogMessage={t('settings.mfa.CONFIRM_DELETION')}
      />
    </>
  )
}
